import { useEffect, useState } from "react"
import { auth } from "@/firebase/firebaseConfig"

const useAuthListener = () => {
  const [userLogin, setUserLogin] = useState(auth.currentUser)
  const [token, setToken] = useState("")

  const handleGetToken = async () => {
    if (!userLogin) {
      return
    }
    const authToken = await userLogin.getIdToken()
    setToken(authToken)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => setUserLogin(user))
    if (userLogin) {
      handleGetToken()
    }
    return unsubscribe
  }, [])

  return { userLogin, token }
}

export default useAuthListener
