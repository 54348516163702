const setApplyMainColor = (mainColor: string | null) => {
  const root = document.getElementById("root")
  const modalRoot = document.getElementById("modal-root")
  if (!root || !mainColor || !modalRoot) {
    return
  }
  root.style.setProperty("--primary", mainColor)
  modalRoot.style.setProperty("--primary", mainColor)
}

export default setApplyMainColor
