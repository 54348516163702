import { Outlet } from "react-router-dom"
import { useEffect } from "react"
import Header from "@/components/Header"
import SideBar from "@/components/SideBar"
import { useAppDispatch, useAppSelector, useAuthListener } from "@/hooks"
import fetchAdminInformation, {
  unsubscribeFetchAdminInformation,
} from "@/redux/store/admin/actions/fetchAdminInformation"
import fetchSettingsFromCompany from "@/redux/store/companySettings/actions"
import { unsubscribeFetchSettingsFromCompany } from "@/redux/store/companySettings/actions/fetchSettingsFromCompany"
import { unsubscribeFetchAllGroups } from "@/redux/store/groups/actions/fetchAllGroups"
import { unsubscribeFetchAllCategories } from "@/redux/store/categories/actions/fetchAllCategories"
import { unsubscribeFetchAllUsers } from "@/redux/store/users/actions/fetchAllUsers"
import { fetchAllCategories } from "@/redux/store/categories/actions"
import { fetchAllGroups } from "@/redux/store/groups/actions"
import { fetchAllUsers } from "@/redux/store/users/actions"
import fetchAllArticles, {
  unsubscribeFetchAllArticles,
} from "@/redux/store/articles/actions/fetchAllArticles"
import Loader from "@/components/Loader"
import { setApplyBaseTheme, setApplyDarkMode, setApplyMainColor } from "@/theme/index"

export const MainLayout = () => {
  const dispatch = useAppDispatch()
  const authStatus = useAuthListener()

  const { userLogin } = authStatus

  const { admin } = useAppSelector((root) => root.admin)
  const { settings, isLoading } = useAppSelector((root) => root.settings)

  const { defaultLanguage } = settings.settings
  const { mainColor, darkMode } = settings.settings.colors

  const changeMainColor = () => {
    const main = localStorage.getItem("mainColor")
    if (main || !mainColor) {
      setApplyMainColor(main)
    }
    if (mainColor) {
      setApplyMainColor(mainColor)
    }
  }

  useEffect(() => {
    if (!authStatus || !userLogin || !userLogin.uid) {
      return
    }
    dispatch(fetchAdminInformation(userLogin.uid))
    return () => unsubscribeFetchAdminInformation()
  }, [userLogin])

  useEffect(() => {
    if (!admin.companyId) {
      return
    }
    dispatch(fetchSettingsFromCompany(admin.companyId))
    dispatch(fetchAllGroups(admin.companyId))
    dispatch(fetchAllUsers(admin.companyId))
    dispatch(fetchAllArticles(admin.companyId))
    dispatch(fetchAllCategories(admin.companyId))
    return () => {
      unsubscribeFetchSettingsFromCompany()
      unsubscribeFetchAllGroups()
      unsubscribeFetchAllUsers()
      unsubscribeFetchAllArticles()
      unsubscribeFetchAllCategories()
    }
  }, [admin.companyId])

  useEffect(() => {
    changeMainColor()
  }, [mainColor])

  // wylaczany dark mode

  // useEffect(() => {
  //   if (!darkMode) {
  //     setApplyBaseTheme()
  //     return
  //   }
  //   setApplyDarkMode()
  // }, [darkMode])

  if (isLoading || !defaultLanguage) {
    return <Loader />
  }

  return (
    <>
      <Header />
      <div className="flex flex-row">
        <SideBar />
        <Outlet />
      </div>
    </>
  )
}
