import { IArticlesReducer } from "@/redux/store/articles/types"
import { ITableHeaderEmployees } from "@/redux/store/users/types"

interface IPushUsers {
  deviceId: string
  lang: string
  userId: string
}

export const handleGetDevicesAndLang = (
  article: IArticlesReducer,
  users: ITableHeaderEmployees[],
  defaultLanguage: string,
) => {
  const groupsId = article.groups.map(({ groupId }) => groupId)
  const usersForPushNotifications = users.filter(({ groups }) => {
    if (!groups) {
      return
    }
    return groups.find((el) => groupsId.includes(el.groupId))
  })
  const parsedUsersForNotifications: IPushUsers[] = []

  usersForPushNotifications.forEach(({ deviceId, mobileLang, userId }) => {
    if (!deviceId) {
      return
    }
    deviceId.forEach((item) => {
      if (!item) {
        return
      }
      parsedUsersForNotifications.push({
        deviceId: item,
        lang: mobileLang ? mobileLang : defaultLanguage,
        userId,
      })
    })
  })

  return parsedUsersForNotifications
}

export const handleEditArticleGetDevicesAndLang = (
  article: IArticlesReducer,
  users: ITableHeaderEmployees[],
  currentEditLanguage: string,
  defaultLanguage: string,
) => {
  const groupsId = article.groups.map(({ groupId }) => groupId)
  const usersForPushNotifications = users.filter(({ groups }) => {
    if (!groups) {
      return
    }
    return groups.find((el) => groupsId.includes(el.groupId))
  })
  const parsedUsersForNotifications: IPushUsers[] = []

  usersForPushNotifications.forEach(({ deviceId, mobileLang, userId }) => {
    if (!deviceId) {
      return
    }

    if (currentEditLanguage !== mobileLang) {
      return
    }

    deviceId.forEach((item) => {
      if (!item) {
        return
      }
      parsedUsersForNotifications.push({
        deviceId: item,
        lang: mobileLang ? mobileLang : defaultLanguage,
        userId,
      })
    })
  })

  return parsedUsersForNotifications
}
