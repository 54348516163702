import React from "react"

interface IProps {
  text: string
  margin?: string
  uppercase?: string
}

const Label: React.FC<IProps> = ({ text, margin, uppercase }) => (
  <label
    className={`${margin && margin} ${uppercase && uppercase} text-greySilver text-xs font-medium`}
  >
    {text}
  </label>
)

export default Label
