import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage"
import { storage } from "@/firebase/firebaseConfig"

export async function uploadCompanyLogo(file: File, company: string) {
  return new Promise(function (resolve, reject) {
    const storageRef = ref(storage, `${company}/${file.name}`)
    const uploadLogo = uploadBytesResumable(storageRef, file)
    uploadLogo.on(
      "state_changed",
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      },
      (error) => {
        reject()
      },
      async () => {
        const url = await getDownloadURL(uploadLogo.snapshot.ref)
        resolve(url)
      },
    )
  })
}
