import React from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import CreatableSelect from "react-select/creatable"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import validationUserSchema from "@/validationSchema/validationUserSchema"
import { useAppDispatch } from "@/hooks"
import useAppSelector from "@/hooks/useAppSelector"
import addNewUser from "@/redux/store/users/actions/addNewUser"
import { clearError } from "@/redux/store/users/reducer"
import { IAddUser } from "@/types/IAddUser"
import { IGroup } from "@/types/IGroup"
import { INPUT_TYPE } from "@/enums/inputType"
import { ERROR_CODE } from "@/enums"
import Label from "@/components/Label"
import Input from "@/components/Inputs/Input"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import { settingsGroupsSelect } from "@/components/Forms/Groups/FormGroup/settingsGroupsSelect"
import Loader from "@/components/Loader"

interface IProps {
  toggleModal: () => void
}

const FormAddUser: React.FC<IProps> = ({ toggleModal }) => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()

  const { error } = useAppSelector((root) => root.users)
  const { groups: allGroups } = useAppSelector((root) => root.groups)
  const { isLoading } = useAppSelector((root) => root.users)
  const { admin } = useAppSelector((root) => root.admin)
  const { settings } = useAppSelector((root) => root.settings)

  const { departments: departmentsList, name: companyName } = settings
  const { companyId } = admin

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IAddUser>({
    shouldUnregister: false,
    resolver: yupResolver(validationUserSchema),
  })

  const onSubmit: SubmitHandler<IAddUser> = async (values) => {
    if (!values) {
      return
    }

    const { groups: groupList, ...rest } = values

    let groups: IGroup[] = []

    if (!groupList) {
      groups = []
    } else {
      groups = groupList.map((item) => ({
        groupId: item.value,
        groupName: item.label,
      }))
    }
    const newUserDetails = {
      groups,
      ...rest,
      companyId,
    }
    await dispatch(addNewUser({ newUserDetails, companyId, toggleModal, companyName }))
  }

  const handleError = (errCode: ERROR_CODE) => {
    switch (errCode) {
      case ERROR_CODE.NUMBER_TOO_SHORT:
        return <div className="text-red text-sm">{t("forms.the_number_is_too_short")}</div>
      case ERROR_CODE.NUMBER_TOO_LONG:
        return <div className="text-red text-sm">{t("forms.the_number_is_too_long")}</div>
      case ERROR_CODE.USER_WITH_THIS_NUMBER_EXISTS:
        return <div className="text-red text-sm">{t("forms.user_already_exists")}</div>
      case ERROR_CODE.INVALID_FORMAT_NUMBER:
        return <div className="text-red text-sm">{t("forms.invalid_number_format")}</div>
      default:
        return
    }
  }

  const departmentsWithoutDuplicate = departmentsList.filter((name) => name)

  const optionsListDepartment = departmentsWithoutDuplicate.map((item) => ({
    value: item,
    label: item,
  }))

  const optionsListGroups = allGroups.map(({ id, groupName }) => ({
    value: id,
    label: groupName,
  }))

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full flex-grow">
      <div className="flex flex-col gap-2 pt-8 px-8 ">
        <Label text={t("forms.name")} />
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="name"
          placeholder={t("forms.placeholderName")}
          modalInput={true}
        />
        <div className="text-red text-sm">{errors.name?.message}</div>
        <Label text={t("forms.position")} />
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="position"
          placeholder={t("forms.placeholderPosition")}
          modalInput={true}
        />
        <div className="text-red text-sm">{errors.position?.message}</div>
        <Label text={t("forms.department")} />
        <Controller
          rules={{ required: true }}
          control={control}
          name="department"
          render={({ field: { onChange } }) => (
            <CreatableSelect
              options={optionsListDepartment}
              onChange={(val) => onChange(val!.label)}
              placeholder={t("forms.placeholderDepartment")}
              className="text-gray-900 text-sm w-440"
              styles={{
                control: (base) => ({
                  ...base,
                  color: "grey",
                  backgroundColor: "var(--white)",
                  width: "440px",
                  border: `1px "var(--inputBorder)" solid`,
                }),
              }}
            />
          )}
        />
        <div className="text-red text-sm">{errors.department?.message}</div>
        <Label text={t("forms.phoneNumber")} />
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="phoneNumber"
          onChange={() => dispatch(clearError())}
          placeholder={t("forms.placeholderPhoneNumber")}
          modalInput={true}
        />
        {error && error.error && handleError(error.error.message as ERROR_CODE)}
        <div className="text-red text-sm">{errors.phoneNumber?.message}</div>
        <Label text={t("forms.groups")} />
        <Controller
          control={control}
          name="groups"
          render={({ field: { onChange } }) => (
            <Select
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              isMulti
              menuPlacement="bottom"
              options={optionsListGroups}
              onChange={(val) => onChange(val)}
              placeholder={t("articles.show_more_groups")}
              styles={settingsGroupsSelect}
              className="e-caret-hide"
            />
          )}
        />
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.add_employee")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormAddUser
