import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { INPUT_TYPE } from "@/enums/inputType"
import { useAppDispatch, useAppSelector, useHandleErrorMessage } from "@/hooks"
import Input from "@/components/Inputs/Input"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import { validationEditCategorySchema } from "@/validationSchema"
import { editCategory } from "@/redux/store/categories/actions"
import { ICategory } from "@/types/ICategory"
import { IEditCategory } from "@/types/IEditCategory"
import { LANGUAGE } from "@/enums"

interface FormEditCategoryProps {
  toggleModal: () => void
  categoryId?: ICategory["categoryId"]
}

const FormEditCategory = ({ toggleModal, categoryId }: FormEditCategoryProps) => {
  const { t } = useTranslation("common")
  const { handleErrorMessage } = useHandleErrorMessage()
  const { admin } = useAppSelector((root) => root.admin)
  const { settings } = useAppSelector((root) => root.settings)
  const { categories } = useAppSelector((root) => root.categories)
  const dispatch = useAppDispatch()
  const { companyId } = admin
  const [filteredCategory] = categories.filter((category) => category.categoryId === categoryId)
  const [validateLangs, setValidateLangs] = useState<string[]>([])
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IEditCategory>({
    resolver: yupResolver(validationEditCategorySchema(validateLangs)),
    mode: "all",
  })

  useEffect(() => {
    (Object.keys(LANGUAGE) as (keyof typeof LANGUAGE)[]).map((key) => {
      if (settings.availableLanguages.includes(LANGUAGE?.[key])) {
        setValidateLangs((prev) => [...prev, `name${key}`])
      }
      filteredCategory[`name${key}`] && setValue(`name${key}`, filteredCategory[`name${key}`])
    })
  }, [categories])

  const onSubmit: SubmitHandler<IEditCategory> = async ({ nameDE, nameEN, namePL, nameUK }) => {
    if (!categoryId) {
      return
    }
    try {
      dispatch(
        editCategory({
          companyId,
          categoryId,
          nameDE: nameDE || "",
          nameEN: nameEN || "",
          namePL: namePL || "",
          nameUK: nameUK || "",
        }),
      )
      toggleModal()
    } catch (error: unknown) {
      handleErrorMessage(error)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full flex-grow justify-between"
    >
      <div className="flex flex-col gap-2 pt-8 px-8 ">
        {settings.availableLanguages.includes(LANGUAGE.PL) && (
          <>
            <label className="text-spunPearl text-xs">{t("forms.category.namePL")}</label>
            <Input
              register={register}
              type={INPUT_TYPE.TEXT}
              id="namePL"
              placeholder={t("forms.category.placeholderName")}
              modalInput={true}
            />
            <div className="text-red text-sm">{errors.namePL?.message}</div>
          </>
        )}
        {settings.availableLanguages.includes(LANGUAGE.EN) && (
          <>
            <label className="text-spunPearl text-xs">{t("forms.category.nameEN")}</label>
            <Input
              register={register}
              type={INPUT_TYPE.TEXT}
              id="nameEN"
              placeholder={t("forms.category.placeholderName")}
              modalInput={true}
            />
            <div className="text-red text-sm">{errors.nameEN?.message}</div>
          </>
        )}
        {settings.availableLanguages.includes(LANGUAGE.DE) && (
          <>
            <label className="text-spunPearl text-xs">{t("forms.category.nameDE")}</label>
            <Input
              register={register}
              type={INPUT_TYPE.TEXT}
              id="nameDE"
              placeholder={t("forms.category.placeholderName")}
              modalInput={true}
            />
            <div className="text-red text-sm">{errors.nameDE?.message}</div>
          </>
        )}
        {settings.availableLanguages.includes(LANGUAGE.UK) && (
          <>
            <label className="text-spunPearl text-xs">{t("forms.category.nameUK")}</label>
            <Input
              register={register}
              type={INPUT_TYPE.TEXT}
              id="nameUK"
              placeholder={t("forms.category.placeholderName")}
              modalInput={true}
            />
            <div className="text-red text-sm">{errors.nameUK?.message}</div>
          </>
        )}
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("knowledge.category.edit_category")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormEditCategory
