import { BrowserRouter } from "react-router-dom"
import { Provider as StoreProvider } from "react-redux"
import { SnackbarProvider } from "notistack"
import { Router } from "@/routes"
import store from "./redux/store"

function App() {
  return (
    <StoreProvider store={store}>
      <SnackbarProvider maxSnack={3}>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </SnackbarProvider>
    </StoreProvider>
  )
}

export default App
