import { createAsyncThunk } from "@reduxjs/toolkit"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { IUser } from "@/types/IUser"
import { COLLECTION_NAME } from "@/enums/collectionName"

const addUsersToGroup = createAsyncThunk(
  "/groups/addUsersToGroup",
  async ({
    groupName,
    companyId,
    groupId,
    usersId,
  }: {
    groupName: string
    companyId: string
    groupId: string
    usersId: IUser[]
  }) => {
    await Promise.all(
      usersId.map(async (item) => {
        const groupRef = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", groupId)
        await updateDoc(groupRef, {
          members: arrayUnion({ ...item }),
        })
      }),
    )

    const id = usersId.map((item) => item.userId)

    await Promise.all(
      id.map(async (item) => {
        const userRef = await doc(db, COLLECTION_NAME.USERS, item)
        await updateDoc(userRef, {
          groups: arrayUnion({
            groupName: groupName,
            groupId: groupId,
          }),
        })
      }),
    )
  },
)

export default addUsersToGroup
