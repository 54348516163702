import * as Yup from "yup"
import i18n from "@/i18n"

const letterRegExp = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ,.\s'-]+$/i

const validationGroupSchema = Yup.object().shape({
  groupName: Yup.string()
    .min(1)
    .required(i18n.t("sign_in.required"))
    .matches(letterRegExp, i18n.t("forms.invalid_format_letters"))
    .max(30),
  members: Yup.array().of(
    Yup.object().shape({
      label: Yup.string(),
      value: Yup.string(),
    }),
  ),
})

export default validationGroupSchema
