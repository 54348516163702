export enum COLLECTION_NAME {
  GROUP = "group",
  USERS = "users",
  COMPANIES = "companies",
  ADMINS = "admins",
  ARTICLES = "articles",
  CATEGORIES = "knowledgeCategory",
  NOTIFICATIONS = "notifications",
  IMAGES = "images",
  VIDEOS = "videos",
}
