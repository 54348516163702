export const darkModeTheme = {
  "--primary": "#1DD75E",
  "--background": "#242424",
  "--white": "#161616",
  "--gunmental": "#F8F9FD",
  "--purple": "#1AC155",
  "--gallery": "#2B2B2B",
  "--whiteSmoke": "#2B2B2B",
  "--inputBorder": "#2B2B2B",
  "--lightGrey": "#626467",
  "--textSelection": "#1DD75E",
}
