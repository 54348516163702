import React from "react"
import ReactDOM from "react-dom"
import Header from "@/components/Modal/Header"
import FormGroup from "@/components/Forms/Groups/FormGroup"
import { MODAL_TYPE } from "@/enums/modalType"
import { IEditUser } from "@/types/IEditUser"
import { IUser } from "@/types/IUser"
import FormEditUser from "@/components/Forms/Employees/FormEditUser"
import FormAddUser from "@/components/Forms/Employees/FormAddUser"
import FormAddToGroup from "@/components/Forms/Employees/FormAddToGroup"
import FormAddPersonToGroup from "@/components/Forms/Groups/FormAddPersonToGroup"
import FormCategory, {
  FormEditCategory,
  FormEditChildCategory,
} from "@/components/Forms/Categories/FormCategory"
import { ICategory } from "@/types/ICategory"

interface IProps {
  isOpenModal: boolean
  toggleModal: () => void
  title: string
  type: MODAL_TYPE
  userEditInformation?: IEditUser
  groupName?: string
  userId?: string
  groupId?: string
  membersList?: IUser[]
  clearTargetUsers?: () => void
  categoryId?: ICategory["categoryId"]
  parentCategoryId?: string
  resetSelectedRow?: () => void
}

const Modal: React.FC<IProps> = ({
  isOpenModal,
  toggleModal,
  type,
  title,
  userId,
  groupName,
  groupId,
  membersList,
  clearTargetUsers,
  categoryId,
  parentCategoryId,
  resetSelectedRow,
}) => {
  const modalSwitch = (modalType: MODAL_TYPE) => {
    switch (modalType) {
      case MODAL_TYPE.ADD_GROUP:
        return <FormGroup toggleModal={toggleModal} />
      case MODAL_TYPE.ADD_PERSON_TO_GROUP:
        return (
          <FormAddPersonToGroup
            membersList={membersList}
            toggleModal={toggleModal}
            groupName={groupName}
            groupId={groupId}
          />
        )
      case MODAL_TYPE.EDIT_USER:
        return <FormEditUser toggleModal={toggleModal} targetUserId={userId!} />
      case MODAL_TYPE.ADD_USER:
        return <FormAddUser toggleModal={toggleModal} />
      case MODAL_TYPE.ADD_USER_TO_GROUP:
        return (
          <FormAddToGroup
            toggleModal={toggleModal}
            membersList={membersList}
            clearTargetUsers={clearTargetUsers}
            resetSelectedRow={resetSelectedRow}
          />
        )
      case MODAL_TYPE.ADD_CATEGORY:
        return <FormCategory toggleModal={toggleModal} />
      case MODAL_TYPE.EDIT_CATEGORY:
        return <FormEditCategory toggleModal={toggleModal} categoryId={categoryId} />
      case MODAL_TYPE.EDIT_CHILD_CATEGORY:
        return (
          <FormEditChildCategory
            toggleModal={toggleModal}
            categoryId={categoryId}
            parentCategoryId={parentCategoryId}
          />
        )
        break
    }
  }

  if (!isOpenModal) {
    return null
  }

  return ReactDOM.createPortal(
    <div
      id="modal-container"
      className="justify-center items-center flex fixed inset-0 z-40 outline-none backdrop-blur-sm backdrop-brightness-50"
    >
      <div className="w-500  flex flex-col bg-white rounded-2xl bg-white w-full">
        <Header title={title} toggleModal={toggleModal} clearTargetUsers={clearTargetUsers} />
        {modalSwitch(type)}
      </div>
    </div>,
    document.getElementById("modal-root")!,
  )
}

export default Modal
