import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteObject, ref } from "@firebase/storage"
import { deleteDoc, doc } from "firebase/firestore"
import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const deleteArticle = createAsyncThunk(
  "/articles/removeArticle",
  async ({ companyId, targetArticleId }: { companyId: string; targetArticleId: string }, extra) => {
    const { articles } = extra.getState().articles

    const storageContentForDelete: string[] = []

    const targetArticle = articles.find(({ uid }) => uid === targetArticleId)

    if (!targetArticle) {
      return
    }
    const { translations } = targetArticle

    if (translations.pl && translations.pl.content.contentUrl.length) {
      storageContentForDelete.push(translations.pl.content.contentUrl)
    }
    if (translations.en && translations.en.content.contentUrl.length) {
      storageContentForDelete.push(translations.en.content.contentUrl)
    }
    if (translations.de && translations.de.content.contentUrl.length) {
      storageContentForDelete.push(translations.de.content.contentUrl)
    }
    if (translations.uk && translations.uk.content.contentUrl.length) {
      storageContentForDelete.push(translations.uk.content.contentUrl)
    }

    await deleteDoc(doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", targetArticleId))

    await Promise.all(
      storageContentForDelete.map(async (item) => await deleteObject(ref(storage, item))),
    )
  },
)

export default deleteArticle
