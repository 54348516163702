import React from "react"
import { components, OptionProps } from "react-select"
import handleInitial from "@/helpers/handleInitial"

export interface SelectInputOptionInterface {
  readonly value: string
  readonly label: string
  readonly position: string
}

const { Option } = components
const CustomOptions: React.FC<OptionProps<any>> = (props) => {
  return (
    <Option {...props}>
      <div className="flex flex-row w-full items-center justify-start gap-4">
        <span className="w-36 h-36 text-white text-sm font-semibold flex items-center justify-center bg-trueBlue rounded-14">
          {handleInitial(props.label)}
        </span>
        <div className="flex flex-col">
          <span className="text-tiny text-darkShadeGray">{props.label}</span>
          <span className="text-mini text-tableNames">{props.data.position}</span>
        </div>
      </div>
    </Option>
  )
}

export default CustomOptions
