import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFunctions, HttpsCallableResult } from "firebase/functions"
import { httpsCallable } from "@firebase/functions"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { INewUser } from "@/types/INewUser"
import { COLLECTION_NAME } from "@/enums/collectionName"

const addNewUser = createAsyncThunk(
  "/users/addNewUser",
  async (
    {
      newUserDetails,
      companyId,
      toggleModal,
      companyName,
    }: {
      newUserDetails: INewUser
      companyId: string
      toggleModal: () => void
      companyName: string
    },
    extra,
  ) => {
    const settings = extra.getState().settings
    const { departments } = settings.settings

    const { name, position, phoneNumber, department, groups } = newUserDetails

    const newUser = {
      name,
      position,
      phoneNumber,
      companyId,
      department,
      groups,
      previousNotifications: [],
      deviceId: [],
      mobileLang: "",
      acceptedRegulation: false,
      companyName,
    }

    const isNewDepartment = departments.some((item) => item.name === department)
    if (!isNewDepartment) {
      const settingsRef = await doc(db, COLLECTION_NAME.COMPANIES, companyId)
      await updateDoc(settingsRef, {
        departments: arrayUnion(department),
      })
    }

    const idGroups = newUserDetails.groups.map((item) => item.groupId)

    const functions = await getFunctions()
    const addUser = await httpsCallable(functions, "createUser")
    await addUser({ ...newUser }).then(async (res: HttpsCallableResult<any>) => {
      const userInfoId = res.data.user.uid
      await Promise.all(
        idGroups.map(async (item) => {
          if (!item) {
            return
          }
          const dbRef = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", item)
          await updateDoc(dbRef, {
            members: arrayUnion({
              userId: userInfoId,
              name,
            }),
          })
        }),
      )
    })
    toggleModal()
  },
)

export default addNewUser
