import { v4 as uuid } from "uuid"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"

import { COLLECTION_NAME } from "@/enums/collectionName"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

const addChildCategory = createAsyncThunk(
  "/knowledgeCategory/addChildCategory",
  async ({
    companyId,
    categoryNames,
    parentCategory,
  }: {
    companyId: string
    categoryNames: ICategoryFormValues["categoryNames"]
    parentCategory: string
  }) => {
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", parentCategory)
    const newId = uuid()
    await updateDoc(dbRef, {
      childCategories: arrayUnion({
        categoryId: newId,
        namePL: categoryNames?.pl || "",
        nameEN: categoryNames?.en || "",
        nameDE: categoryNames?.de || "",
        nameUK: categoryNames?.uk || "",
        documents: [],
      }),
    })
  },
)

export default addChildCategory
