import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"
import { Link } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"
import { NAVIGATION } from "@/enums"
import Modal from "@/components/Modal/Modal"
import { MODAL_TYPE } from "@/enums/modalType"
import { SIDE_BAR_TYPE } from "@/enums/sideBarType"

interface IProps {
  clearHoveredItem: () => void
  hoveredItem: string
}

const HoveredNav: React.FC<IProps> = ({ hoveredItem, clearHoveredItem }) => {
  const [isOpenModalAddUser, setIsOpenModalAddUser] = useState(false)

  const toggleModalAddUser = () => setIsOpenModalAddUser((prevState) => !prevState)

  const { t } = useTranslation("common")

  const SideBarClassName = classNames({
    "w-260  left-20 bg-lightGrey h-[calc(100vh-80px)] z-10 absolute flex flex-col gap-4 items-start py-16 drop-shadow will-change-auto":
      hoveredItem && hoveredItem !== SIDE_BAR_TYPE.SETTINGS,
    hidden: !hoveredItem && hoveredItem === SIDE_BAR_TYPE.SETTINGS,
  })

  return (
    <>
      <Modal
        isOpenModal={isOpenModalAddUser}
        toggleModal={toggleModalAddUser}
        title={t("users.add_new_employee")}
        type={MODAL_TYPE.ADD_USER}
      />
      <div
        className={SideBarClassName}
        onMouseLeave={clearHoveredItem}
        style={{ willChange: "filter" }}
      >
        {hoveredItem === SIDE_BAR_TYPE.GROUPS && (
          <>
            <span className="text-xl text-mostlyBlack pl-12">{t("sideBar.people")}</span>
            <button
              className="bg-primary rounded-20 h-30 w-120 text-sm text-white ml-9 mt-4"
              onClick={toggleModalAddUser}
            >
              + {t("sideBar.new_employee")}
            </button>
            <ul className="flex flex-col gap-8 pt-8 pl-9">
              <Link key={NAVIGATION.EMPLOYEES + uuidv4()} to={NAVIGATION.EMPLOYEES}>
                <li className="text-primary">{t("sideBar.employees")}</li>
              </Link>
              <Link key={NAVIGATION.GROUPS + uuidv4()} to={NAVIGATION.GROUPS}>
                <li className="text-primary">{t("sideBar.groups")}</li>
              </Link>
            </ul>
          </>
        )}
        {hoveredItem === SIDE_BAR_TYPE.KNOWLEDGE && (
          <>
            <span className="text-xl text-mostlyBlack pl-12">{t("sideBar.knowledge")}</span>
            <ul className="flex flex-col gap-8 pt-8 pl-9">
              <Link key={NAVIGATION.KNOWLEDGE + uuidv4()} to={NAVIGATION.KNOWLEDGE}>
                <li className="text-primary">{t("sideBar.go_to_categories")}</li>
              </Link>
            </ul>
          </>
        )}
        {hoveredItem === SIDE_BAR_TYPE.ARTICLES && (
          <>
            <span className="text-xl text-mostlyBlack pl-12">{t("sideBar.articles")}</span>
            <Link
              to={NAVIGATION.TYPE_ARTICLE}
              className="bg-primary flex items-center justify-center rounded-20 h-30 w-120 text-sm text-white ml-9 mt-4"
            >
              + {t("sideBar.new_article")}
            </Link>
            <ul className="flex flex-col gap-8 pt-8 pl-9">
              <Link key={NAVIGATION.ARTICLES + uuidv4()} to={NAVIGATION.ARTICLES}>
                <li className="text-primary">{t("sideBar.go_to_articles")}</li>
              </Link>
            </ul>
          </>
        )}
      </div>
    </>
  )
}

export default HoveredNav
