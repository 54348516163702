import i18n from "@/i18n"
import { PUSH_NOTIFICATIONS_URL } from "@/firebase/rest/pushNotificationsUrl"
import { LANGUAGE } from "@/enums"

interface IPushMessage {
  token: string
  title: string
  message: string
  postId: string
}

const handlePushNotifications = async (
  deviceId: any,
  articleId: string,
  lang: string,
  userId: string,
  author: string,
  handleErrorMessage: (error: unknown) => void,
  token: string,
) => {
  if (!deviceId) {
    return
  }
  const payload = handlePayloadLanguage(lang, deviceId, articleId, author)

  const response = await handlePostFetch(payload, userId, handleErrorMessage, token)

  return response
}

const handlePostFetch = async (
  payload: IPushMessage,
  userId: string,
  handleErrorMessage: (error: unknown) => void,
  token: string,
) => {
  try {
    const res = await fetch(PUSH_NOTIFICATIONS_URL, {
      method: "POST",
      body: JSON.stringify(payload),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
    const response = await res.json()
    return response
  } catch (error: unknown) {
    // TODO do sprawdzenia czy łapie error
    handleErrorMessage(error)
  }
}

const handlePayloadLanguage = (
  lang: string,
  deviceId: string,
  articleId: string,
  author: string,
) => {
  let payload

  switch (lang) {
    case LANGUAGE.PL:
      return (payload = {
        token: deviceId,
        title: "Wemly",
        message: i18n.t("articles.push_message", { author: author }),
        postId: articleId,
      })
    case LANGUAGE.EN:
      return (payload = {
        token: deviceId,
        title: "Wemly",
        message: i18n.t("articles.push_message", { author: author }),
        postId: articleId,
      })
    case LANGUAGE.UK:
      return (payload = {
        token: deviceId,
        title: "Wemly",
        message: i18n.t("articles.push_message", { author: author }),
        postId: articleId,
      })
    case LANGUAGE.DE:
      return (payload = {
        token: deviceId,
        title: "Wemly",
        message: i18n.t("articles.push_message", { author: author }),
        postId: articleId,
      })
    default:
      return (payload = {
        token: deviceId,
        title: "Wemly",
        message: i18n.t("articles.push_message", { author: author }),
        postId: articleId,
      })
  }
  return payload
}

export default handlePushNotifications
