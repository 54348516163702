import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, documentId, getDocs, query, where } from "@firebase/firestore"
import { doc, getDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { IUser } from "@/types/IUser"
import { setUsers } from "@/redux/store/users/reducer"
import { COLLECTION_NAME } from "@/enums/collectionName"

interface IInfoFetchGroup {
  companyId: string
  groupId: string
}

let usersList = []

const fetchUsersFromGroupCollection = createAsyncThunk(
  "/users/fetchUsersFromGroupCollection",
  async ({ fetchParams }: { fetchParams: IInfoFetchGroup }, extra) => {
    const { groupId, companyId } = fetchParams

    const userIds = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", groupId)
    const querySnapShotUsersId = await getDoc(userIds)
    const membersId: IUser[] = querySnapShotUsersId.data()!.members

    const ids = membersId.map((item) => item.userId)

    const q = await query(
      collection(db, COLLECTION_NAME.USERS),
      where(documentId(), "in", [...ids]),
    )
    const querySnapShot = await getDocs(q)
    usersList = querySnapShot.docs.map((item) => {
      const { name, position, department, phoneNumber, addedAt } = item.data()
      return {
        userId: item.id,
        phoneNumber,
        department,
        addedAt,
        name: {
          fullName: name,
          position,
        },
      }
    })
    extra.dispatch(setUsers(usersList))
  },
)

export default fetchUsersFromGroupCollection
