import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router"
import classNames from "classnames"
import { FiChevronDown, FiChevronUp } from "react-icons/fi"
import { logout } from "@/firebase/firebaseHelpers/auth"
import { useAppSelector, useHandleErrorMessage } from "@/hooks"
import { ReactComponent as LogoWeemly } from "@/assets/images/logo/logoWeemly.svg"
import { ReactComponent as LogoutIcon } from "@/assets/images/header/logoutHeader.svg"
import { ReactComponent as EditIcon } from "@/assets/images/header/editHeader.svg"
import { NAVIGATION } from "@/enums"

const Header = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [logoCompany, setLogoCompany] = useState("")

  const navigate = useNavigate()

  const { t } = useTranslation("common")

  const { handleErrorMessage } = useHandleErrorMessage()

  const dropdownRef = useRef<HTMLUListElement>(null)

  const handleToggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  const handleLogout = () => {
    logout(handleErrorMessage)
    navigate("/login", { replace: true })
  }

  const closeOpenMenus = (event: any) => {
    if (dropdownRef.current && isOpen && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", closeOpenMenus)
    return () => document.removeEventListener("mousedown", closeOpenMenus)
  })

  const { settings } = useAppSelector((root) => root.settings)
  const { admin } = useAppSelector((root) => root.admin)
  const { authorImage } = admin

  const { logo } = settings.settings

  useEffect(() => {
    setLogoCompany(logo)
  }, [logo])

  const dropdownClass = classNames({
    "mt-3 flex flex-col absolute drop-shadow -left-24 z-50 w-200 bg-white h-120 rounded-xl will-change-auto ":
      isOpen,
    hidden: !isOpen,
  })

  return (
    <div className="w-screen h-80 flex justify-between items-center px-30 py-30 z-50 bg-white">
      {logoCompany ? <img src={logoCompany} width={93} height={30} /> : <LogoWeemly />}
      <div className="flex flex-row gap-5 cursor-pointer" onClick={handleToggleDropDown}>
        {authorImage && authorImage.storageUrl ? (
          <img src={authorImage.storageUrl} width={43} height={43} className="rounded-14" />
        ) : (
          <img
            src={require("../../assets/images/admin/adminDefault.jpg")}
            width={43}
            height={43}
            className="rounded-14"
          />
        )}
        <div className="dropdown relative">
          <div className="flex flex-col">
            <span className="text-primary font-semibold">{admin.name}</span>
            <span className="text-littleGrey text-sm">{t("home.administration")}</span>
          </div>
          <ul className={dropdownClass} ref={dropdownRef}>
            <Link
              to={NAVIGATION.USER_SETTINGS}
              className="flex items-center text-gunmetal flex-row text-tiny h-1/2 gap-5 pl-20 pt-6 w-full hover:bg-grey hover:rounded-xl"
            >
              <EditIcon />
              {t("home.edit_my_details")}
            </Link>
            <li
              className="flex flex-row items-center h-1/2 text-tiny pl-20 pt-6 mb-0 gap-5 text-deleteButton hover:bg-grey hover:text-white hover:rounded-xl"
              onClick={handleLogout}
            >
              <LogoutIcon />
              {t("home.logout")}
            </li>
          </ul>
        </div>
        <button onClick={handleToggleDropDown}>
          {isOpen ? (
            <FiChevronUp className="text-dropDownGrey" />
          ) : (
            <FiChevronDown className="text-dropDownGrey" />
          )}
        </button>
      </div>
    </div>
  )
}

export default Header
