export const GROUPS = "groups"
export const USERS = "users"
export const SETTINGS = "settings"
export const CATEGORIES = "categories"
export const ADMIN = "admin"
export const ERRORS = "errors"
export const ARTICLES = "articles"
export const DOCUMENTS = "documents"
export const IMAGES = "images"
export const VIDEOS = "videos"
