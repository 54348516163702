import { lazy } from "react"
import { AuthLayout } from "@/layouts"
import { GuestGuard } from "./guards"
import { SuspenseComponent } from "."

const Login = SuspenseComponent(lazy(() => import("../pages/Login")))
const Forget = SuspenseComponent(lazy(() => import("../pages/ForgetPassword/ForgetPassword")))
const Reset = SuspenseComponent(lazy(() => import("../pages/ResetPassword/ResetPassword")))

export const authRoutes = {
  element: (
    <GuestGuard>
      <AuthLayout />
    </GuestGuard>
  ),
  children: [
    {
      path: "login",
      element: Login,
    },
    {
      path: "forget",
      element: Forget,
    },
    {
      path: "reset",
      element: Reset,
    },
  ],
}
