import { createAsyncThunk } from "@reduxjs/toolkit"
import {
  collection,
  limit,
  onSnapshot,
  query,
  startAfter,
  Unsubscribe,
  orderBy,
} from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { addImages, setImages, setLastElementId } from "@/redux/store/images/reducer"
import { ImagesReducer } from "@/redux/store/images/types"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ITEMS_PER_PAGE } from "@/consts/itemsPerPage"

let unsubscribeFetchImages: Unsubscribe

const fetchImages = createAsyncThunk(
  "/images/fetchImages",
  async ({ id }: { id: string }, extra) => {
    let data: ImagesReducer[] = []
    const { lastElementId } = extra.getState().images
    const imagesRef = collection(db, COLLECTION_NAME.IMAGES, id, "list")

    if (!lastElementId) {
      const queryImages = query(imagesRef, orderBy("createdAt", "desc"), limit(ITEMS_PER_PAGE))
      unsubscribeFetchImages = onSnapshot(queryImages, (doc) => {
        extra.dispatch(setLastElementId(doc.docs[doc.docs.length - 1]))
        data = doc.docs.map((item) => {
          const { storageId, storageUrl, tags } = item.data()
          return {
            id: item.id,
            storageId,
            storageUrl,
            tags,
          }
        })
        extra.dispatch(setImages(data))
      })
      return
    }
    const queryImagesNextPage = query(
      imagesRef,
      orderBy("createdAt", "desc"),
      limit(ITEMS_PER_PAGE),
      startAfter(lastElementId),
    )
    unsubscribeFetchImages = onSnapshot(queryImagesNextPage, (doc) => {
      extra.dispatch(setLastElementId(doc.docs[doc.docs.length - 1]))
      data = doc.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(addImages(data))
    })
  },
)

export { unsubscribeFetchImages }

export default fetchImages
