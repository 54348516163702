import { useSnackbar } from "notistack"

const useHandleErrorMessage = () => {
  const { enqueueSnackbar } = useSnackbar()

  const handleErrorMessage = (error: unknown) => {
    if (typeof error === "string") {
      enqueueSnackbar(error, {
        variant: "error",
      })
    }
    if (error instanceof Error) {
      enqueueSnackbar(error.message, {
        variant: "error",
      })
    }
  }
  return { handleErrorMessage }
}

export default useHandleErrorMessage
