import React from "react"
import { useAppDispatch } from "@/hooks"
import { clearError } from "@/redux/store/users/reducer"

interface IProps {
  toggleModal: () => void
  submitText: string
  cancelText: string
  clearTargetUsers?: () => void
}

const BottomSideForm: React.FC<IProps> = ({
  toggleModal,
  submitText,
  cancelText,
  clearTargetUsers,
}) => {
  const dispatch = useAppDispatch()

  const handleCancel = () => {
    dispatch(clearError())
    if (clearTargetUsers) {
      clearTargetUsers()
    }

    toggleModal()
  }

  return (
    <div className="h-80 mt-8 border border-gallery  rounded-br-2xl rounded-bl-2xl items-center flex justify-between px-9 bg-gallery">
      <button
        type="submit"
        className="bg-green h-9 w-fit justify-center flex items-center text-white p-2 border rounded-8 text-[13px] font-semibold"
      >
        {submitText}
      </button>
      <button
        className="flex items-center text-cancelColor text-[13px] font-semibold"
        onClick={handleCancel}
      >
        {cancelText}
      </button>
    </div>
  )
}

export default BottomSideForm
