import { createAsyncThunk } from "@reduxjs/toolkit"
import { getDownloadURL, ref } from "@firebase/storage"
import { doc, orderBy, updateDoc } from "firebase/firestore"
import { collection, getDocs, query, where } from "@firebase/firestore"
import { db, storage } from "@/firebase/firebaseConfig"
import uploadAdminImage from "@/firebase/firebaseHelpers/user/uploadAdminImage"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { IEditAdmin } from "@/types/IEditAdmin"

const editAdminInformation = createAsyncThunk(
  "/admin/editAdminInformation",
  async (
    {
      newAdminDetails,
      id,
      handleErrorMessage,
      token,
    }: {
      newAdminDetails: IEditAdmin
      id: string
      handleErrorMessage: (error: unknown) => void
      token: string
    },
    extra,
  ) => {
    const { picture, ...rest } = newAdminDetails
    const { articles } = extra.getState().articles
    const { companyId, name } = extra.getState().admin.admin

    const adminRef = doc(db, COLLECTION_NAME.ADMINS, id)

    const articlesWithAdminImage = articles.filter(({ author }) => author === name)

    await Promise.all(
      articlesWithAdminImage.map(async (article) => {
        const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", article.uid)
        await updateDoc(articleRef, {
          author: newAdminDetails.name,
        })
      }),
    )

    let isNameChanged = false

    if (newAdminDetails.name !== name) {
      isNameChanged = true
    }

    // przypadek gdy ktos zmieni zdjecie admina oraz jego nazwe

    if (isNameChanged && picture && typeof picture !== "string") {
      const res = await uploadAdminImage(
        newAdminDetails.companyId,
        picture,
        handleErrorMessage,
        token,
      )
      const url = await getDownloadURL(ref(storage, res.name))

      const newStorageId = picture.name

      const filteredArticlesWithRelatedArticles = articlesWithAdminImage.map((article) => ({
        ...article,
        relatedArticles: article.relatedArticles.map((related) => ({
          ...related,
          author: related.author === name ? newAdminDetails.name : related.author,
          authorImage:
            related.author === name
              ? {
                  storageId: newStorageId,
                  storageUrl: url,
                }
              : {
                  storageId: related.authorImage.storageId,
                  storageUrl: related.authorImage.storageUrl,
                },
        })),
      }))

      //zmieniamy w notyfikacjach tam gdzie bylo stare zdjecie admina na nowe

      const notificationsRef = collection(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list")
      const queryNotifications = query(
        notificationsRef,
        where("author", "==", name),
        orderBy("createdAt", "desc"),
      )
      const snapshot = await getDocs(queryNotifications)

      await Promise.all(
        snapshot.docs.map(async (item) => {
          const updateRef = doc(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list", item.id)
          await updateDoc(updateRef, {
            author: newAdminDetails.name,
            authorImage: {
              storageId: newStorageId,
              storageUrl: url,
            },
          })
        }),
      )

      if (filteredArticlesWithRelatedArticles.length) {
        await Promise.all(
          filteredArticlesWithRelatedArticles.map(async (article) => {
            const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", article.uid)
            await updateDoc(articleRef, {
              author: newAdminDetails.name,
              authorImage: {
                storageId: newStorageId,
                storageUrl: url,
              },
              relatedArticles: article.relatedArticles,
            })
          }),
        )
      }

      await updateDoc(adminRef, {
        authorImage: {
          storageId: newStorageId,
          storageUrl: url,
        },
        ...rest,
      })
      return
    }

    // przypadek gdy ktos zmieni imie admina i nie zmieni zdjecia

    if (isNameChanged && picture && typeof picture === "string") {
      const notificationsRef = collection(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list")
      const queryNotifications = query(
        notificationsRef,
        where("author", "==", name),
        orderBy("createdAt", "desc"),
      )
      const snapshot = await getDocs(queryNotifications)

      await Promise.all(
        snapshot.docs.map(async (item) => {
          const updateRef = doc(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list", item.id)
          await updateDoc(updateRef, {
            author: name,
          })
        }),
      )

      const filteredRelatedWithSameName = articlesWithAdminImage.map((article) => ({
        ...article,
        relatedArticles: article.relatedArticles.map((related) => ({
          ...related,
          author: related.author === name ? newAdminDetails.name : related.author,
        })),
      }))

      if (filteredRelatedWithSameName.length) {
        await Promise.all(
          filteredRelatedWithSameName.map(async (article) => {
            const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", article.uid)
            await updateDoc(articleRef, {
              author: newAdminDetails.name,
              relatedArticles: article.relatedArticles,
            })
          }),
        )
      }
      const nameFile = ref(storage, picture as string).name
      await updateDoc(adminRef, {
        authorImage: {
          storageId: nameFile,
          storageUrl: picture,
        },
        ...rest,
      })

      return
    }

    // przypadek gdy ktos zmieni zdjecie admina i nie zmieni nazwy

    if (!isNameChanged && picture && typeof picture !== "string") {
      const res = await uploadAdminImage(
        newAdminDetails.companyId,
        picture,
        handleErrorMessage,
        token,
      )
      const url = await getDownloadURL(ref(storage, res.name))

      const newStorageId = picture.name

      const filteredArticlesWithRelatedArticles = articlesWithAdminImage.map((article) => ({
        ...article,
        relatedArticles: article.relatedArticles.map((related) => ({
          ...related,
          authorImage:
            related.author === name
              ? {
                  storageId: newStorageId,
                  storageUrl: url,
                }
              : {
                  storageId: related.authorImage.storageId,
                  storageUrl: related.authorImage.storageUrl,
                },
        })),
      }))

      //zmieniamy w notyfikacjach tam gdzie bylo stare zdjecie admina na nowe
      const notificationsRef = collection(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list")
      const queryNotifications = query(
        notificationsRef,
        where("author", "==", name),
        orderBy("createdAt", "desc"),
      )
      const snapshot = await getDocs(queryNotifications)

      await Promise.all(
        snapshot.docs.map(async (item) => {
          const updateRef = doc(db, COLLECTION_NAME.NOTIFICATIONS, companyId, "list", item.id)
          await updateDoc(updateRef, {
            authorImage: {
              storageId: newStorageId,
              storageUrl: url,
            },
          })
        }),
      )

      if (filteredArticlesWithRelatedArticles.length) {
        await Promise.all(
          filteredArticlesWithRelatedArticles.map(async (article) => {
            const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", article.uid)
            await updateDoc(articleRef, {
              authorImage: {
                storageId: newStorageId,
                storageUrl: url,
              },
              relatedArticles: article.relatedArticles,
            })
          }),
        )

        await updateDoc(adminRef, {
          authorImage: {
            storageId: newStorageId,
            storageUrl: url,
          },
          ...rest,
        })

        return
      }
    }

    if (picture) {
      const nameFile = ref(storage, picture as string).name
      await updateDoc(adminRef, {
        authorImage: {
          storageId: nameFile,
          storageUrl: picture,
        },
        ...rest,
      })
      return
    }

    await updateDoc(adminRef, {
      authorImage: {
        storageId: "",
        storageUrl: "",
      },
      ...rest,
    })
    return
  },
)

export default editAdminInformation
