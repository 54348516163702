import { createSlice } from "@reduxjs/toolkit"
import { GROUPS } from "@/consts/reducer"
import { IGroupReducer, IError } from "@/redux/store/groups/types"
import {
  removeGroup,
  addNewGroup,
  fetchAllGroups,
  removeUserFromGroup,
  removeManyUsersFromGroup,
} from "@/redux/store/groups/actions"
import addUsersToGroup from "./actions/addUsersToGroup"

export const initialGroup: IGroupReducer[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  groups: initialGroup,
  isLoading: false,
  error: initialError,
}

const groupSlice = createSlice({
  name: GROUPS,
  initialState,
  reducers: {
    setGroup: (state, action) => {
      state.groups = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllGroups.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchAllGroups.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchAllGroups.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })

    builder.addCase(addNewGroup.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(addNewGroup.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addNewGroup.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })

    builder.addCase(removeGroup.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeGroup.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeGroup.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })

    builder.addCase(removeUserFromGroup.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeUserFromGroup.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeUserFromGroup.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(removeManyUsersFromGroup.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeManyUsersFromGroup.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeManyUsersFromGroup.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })

    builder.addCase(addUsersToGroup.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(addUsersToGroup.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addUsersToGroup.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const { setGroup } = groupSlice.actions

export default groupSlice.reducer
