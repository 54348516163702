import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFunctions } from "firebase/functions"
import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { httpsCallable } from "@firebase/functions"
import { db } from "@/firebase/firebaseConfig"
import { IUser } from "@/types/IUser"
import { COLLECTION_NAME } from "@/enums/collectionName"

const deleteManyUsers = createAsyncThunk(
  "/users/deleteManyUsers",
  async ({ members, companyId }: { members: IUser[]; companyId: string }, extra) => {
    const users = members.map((item) => item.userId)

    const state = extra.getState()
    const { users: stateUsers } = state.users

    const deleteUsers = stateUsers.filter((item) => {
      if (users.includes(item.userId)) {
        return item
      }
    })

    await Promise.all(
      deleteUsers.map(async (user) => {
        if (!user.groups) {
          return
        }
        user.groups.map(async (group) => {
          const groupRef = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", group.groupId)
          await updateDoc(groupRef, {
            members: arrayRemove({
              userId: user.userId,
              name: user.name.fullName,
            }),
          })
        })
      }),
    )
    const functions = await getFunctions()
    const deleteAction = await httpsCallable(functions, "deleteUsers")
    await deleteAction({ users })
  },
)

export default deleteManyUsers
