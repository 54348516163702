import * as Yup from "yup"
import i18n from "@/i18n"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

const spaces = /^(?!\s+$).*/

const nameShema = Yup.string().matches(spaces, i18n.t("forms.category.error.invalid"))

const validationCategorySchema = (langs: ICategoryFormValues["categoryNames"]) =>
  Yup.object().shape({
    categoryNames: Yup.object().shape(
      Object.keys(langs).reduce(
        (prevValue, currentLang) => ({
          ...prevValue,
          [currentLang]: nameShema,
        }),
        {},
      ),
    ),
    parentCategory: Yup.string().required(i18n.t("forms.category.error.parentCategory")),
  })

export default validationCategorySchema
