export enum NAVIGATION {
  LOGIN = "/login",
  HOME = "/",
  FORGET = "/forget",
  RESET = "/reset",
  GROUP = "/group/",
  GROUPS = "/groups",
  ARTICLES = "/articles",
  SETTINGS = "/settings",
  KNOWLEDGE = "/knowledge",
  EMPLOYEES = "/employees",
  TYPE_ARTICLE = "/typeArticle",
  NEW_ARTICLE = "/newArticle",
  ARTICLE = "/article",
  USER_SETTINGS = "/userSettings",
}
