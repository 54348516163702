import { useState } from "react"
import { Link } from "react-router-dom"
import { useLocation } from "react-router"
import { v4 as uuidv4 } from "uuid"
import { useAppSelector } from "@/hooks"
import { ReactComponent as Articles } from "@/assets/images/sideBar/articles.svg"
import { ReactComponent as ActiveArticles } from "@/assets/images/sideBar/activeArticles.svg"
import { ReactComponent as Groups } from "@/assets/images/sideBar/groups.svg"
import { ReactComponent as ActiveGroups } from "@/assets/images/sideBar/activeGroups.svg"
import { ReactComponent as Knowledge } from "@/assets/images/sideBar/knowledge.svg"
import { ReactComponent as ActiveKnowledge } from "@/assets/images/sideBar/activeKnowledge.svg"
import { ReactComponent as Settings } from "@/assets/images/sideBar/settings.svg"
import { ReactComponent as ActiveSettings } from "@/assets/images/sideBar/activeSettings.svg"
import { ReactComponent as WemlyLogo } from "@/assets/images/sideBar/wemlyLogo.svg"
import { NAVIGATION } from "@/enums"
import { SIDE_BAR_TYPE } from "@/enums/sideBarType"
import HoveredNav from "@/components/SideBar/HoveredNav"

const sideBarIconsTop = [
  {
    text: SIDE_BAR_TYPE.KNOWLEDGE,
    icon: <Knowledge />,
    activeIcon: <ActiveKnowledge />,
    path: NAVIGATION.KNOWLEDGE,
  },
  {
    text: SIDE_BAR_TYPE.ARTICLES,
    icon: <Articles />,
    path: NAVIGATION.ARTICLES,
    activeIcon: <ActiveArticles />,
  },
  {
    text: SIDE_BAR_TYPE.GROUPS,
    icon: <Groups />,
    path: NAVIGATION.GROUPS,
    activeIcon: <ActiveGroups />,
  },
]

const SideBar = () => {
  const { pathname } = useLocation()
  const { settings } = useAppSelector((root) => root.settings)

  const { darkMode } = settings.settings.colors

  const [hoveredItem, setHoveredItem] = useState("")

  const clearHoveredItem = () => setHoveredItem("")

  const activeLi = "flex flex-row w-50 rounded-10 h-50 justify-center  items-center bg-purple"
  const unActiveLi =
    "flex flex-row  w-50 h-50  justify-center  items-center hover:bg-purple hover:rounded-10 hover:w-50"

  return (
    <div className="flex flex-row">
      <div className="w-80 h-[calc(100vh-80px)] bg-primary flex flex-col items-center justify-between py-40">
        <div className="flex flex-row w-full items-center">
          <ul className="flex items-center flex-col gap-9 py-5 w-full justify-center m-0">
            {sideBarIconsTop.map(({ path, text, icon, activeIcon }) => {
              return (
                <Link key={text + uuidv4()} to={path} onMouseEnter={() => setHoveredItem(text)}>
                  <li className={pathname === path ? activeLi : unActiveLi}>
                    <span className="flex text-white text-tiny">
                      {pathname === path ? activeIcon : icon}
                    </span>
                  </li>
                </Link>
              )
            })}
            <Link key={"settings" + uuidv4()} to={NAVIGATION.SETTINGS}>
              <li className={pathname === NAVIGATION.SETTINGS ? activeLi : unActiveLi}>
                <span className="flex text-white text-tiny">
                  {pathname === NAVIGATION.SETTINGS ? <ActiveSettings /> : <Settings />}
                </span>
              </li>
            </Link>
          </ul>
        </div>
        <div>
          <a target="_blank" href="https://www.wemly.app/" rel="noreferrer">
            <WemlyLogo />
          </a>
        </div>
      </div>
      <HoveredNav hoveredItem={hoveredItem} clearHoveredItem={clearHoveredItem} />
    </div>
  )
}

export default SideBar
