export enum LANGUAGE {
  PL = "pl",
  EN = "en",
  DE = "de",
  UK = "uk",
}

export enum FULL_LANGUAGE {
  pl = "settings.polish",
  en = "settings.english",
  de = "settings.deutsch",
  uk = "settings.ukrainian",
}
