import React from "react"
import { Navigate } from "react-router-dom"
import { useLocation } from "react-router"
import { useAuthListener } from "@/hooks"
import { NAVIGATION } from "@/enums"

interface Props {
  children: React.ReactNode
}

interface CustomizedState {
  interceptedPath: string
}

export const GuestGuard = ({ children }: Props) => {
  const authStatus = useAuthListener()
  const { userLogin } = authStatus

  const location = useLocation()
  const state = location.state as CustomizedState

  if (userLogin === undefined) {
    // Tu dać loading screen - teraz jest tymczsowo
    return <p>Loading</p>
  }

  if (userLogin && state) {
    const path = state.interceptedPath
    return <Navigate to={path} />
  }

  if (userLogin) {
    return <Navigate to={NAVIGATION.ARTICLES} />
  }

  return <>{children}</>
}
