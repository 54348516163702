import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteDoc, doc } from "firebase/firestore"
import { deleteObject, ref } from "@firebase/storage"

import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const removeCategory = createAsyncThunk(
  "/knowledgeCategory/removeCategory",
  async ({
    companyId,
    categoryId,
    urlsToDelete,
    urlsContentToDelete,
  }: {
    companyId: string
    categoryId: string
    urlsToDelete: string[]
    urlsContentToDelete: string[]
  }) => {
    await deleteDoc(doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", categoryId))

    await Promise.all(urlsContentToDelete.map(async (url) => await deleteObject(ref(storage, url))))

    await Promise.all(urlsToDelete.map(async (url) => await deleteObject(ref(storage, url))))
  },
)

export default removeCategory
