import { lazy } from "react"
import { MainLayout } from "@/layouts"
import { AuthGuard } from "./guards"
import { SuspenseComponent } from "."

const Groups = SuspenseComponent(lazy(() => import("../pages/Groups")))
const GroupTable = SuspenseComponent(lazy(() => import("../pages/GroupTable")))
const Employees = SuspenseComponent(lazy(() => import("../pages/Employees")))
const Settings = SuspenseComponent(lazy(() => import("../pages/Settings")))
const Articles = SuspenseComponent(lazy(() => import("../pages/Articles")))
const Article = SuspenseComponent(lazy(() => import("../pages/Article")))
const TypeArticle = SuspenseComponent(lazy(() => import("../pages/TypeArticle")))
const NewArticle = SuspenseComponent(lazy(() => import("../pages/NewArticle")))
const Knowledge = SuspenseComponent(lazy(() => import("../pages/Knowledge")))
const CategoryDocuments = SuspenseComponent(
  lazy(() => import("../pages/Documents/CategoryDocuments")),
)
const ChildCategoryDocuments = SuspenseComponent(
  lazy(() => import("../pages/Documents/ChildCategoryDocuments")),
)
const UserSettings = SuspenseComponent(lazy(() => import("../pages/UserSettings")))
const NewDocument = SuspenseComponent(lazy(() => import("../pages/Documents/NewDocument")))
const EditChildDocument = SuspenseComponent(
  lazy(() => import("../pages/Documents/EditChildDocument")),
)
const EditCategoryDocument = SuspenseComponent(
  lazy(() => import("../pages/Documents/EditCategoryDocument")),
)

export const homeRoutes = {
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: Articles,
    },
    {
      path: "/groups",
      element: Groups,
    },
    {
      path: "/group/:id",
      element: GroupTable,
    },
    {
      path: "/employees",
      element: Employees,
    },
    {
      path: "/settings",
      element: Settings,
    },
    {
      path: "/articles",
      element: Articles,
    },
    {
      path: "/typeArticle",
      element: TypeArticle,
    },
    {
      path: "/newArticle/:type",
      element: NewArticle,
    },
    {
      path: "/article/:id",
      element: Article,
    },
    {
      path: "/knowledge",
      element: Knowledge,
    },
    {
      path: "/knowledge/category/documents/:id",
      element: CategoryDocuments,
    },
    {
      path: "/knowledge/child_category/documents/:parentId/:categoryId",
      element: ChildCategoryDocuments,
    },
    {
      path: "/userSettings",
      element: UserSettings,
    },
    {
      path: "/knowledge/category/documents/:categoryId/new_document",
      element: NewDocument,
    },
    {
      path: "/knowledge/child_category/documents/:parentId/:childCategoryId/new_document",
      element: NewDocument,
    },
    {
      path: "/knowledge/child_category/documents/:parentId/:childCategoryId/:childDocumentId/edit_document",
      element: EditChildDocument,
    },
    {
      path: "/knowledge/category/documents/:categoryId/:documentId/edit_document",
      element: EditCategoryDocument,
    },
  ],
}
