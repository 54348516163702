import dayjs from "dayjs"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, onSnapshot, Unsubscribe } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { setArticles } from "@/redux/store/articles/reducer"
import { COLLECTION_NAME } from "@/enums/collectionName"

let unsubscribeFetchAllArticles: Unsubscribe
let data

const fetchAllArticles = createAsyncThunk(
  "/articles/fetchAllArticles",
  async (id: string, extra) => {
    unsubscribeFetchAllArticles = onSnapshot(
      collection(db, COLLECTION_NAME.ARTICLES, id, "list"),
      (doc) => {
        data = doc.docs.map((item) => {
          const { createdAt, ...rest } = item.data()
          return {
            uid: item.id,
            createdAt: dayjs(createdAt.toDate()).toString(),
            ...rest,
          }
        })
        if (!data) {
          return
        }
        extra.dispatch(setArticles(data))
      },
    )
  },
)

export { unsubscribeFetchAllArticles }

export default fetchAllArticles
