import { createAsyncThunk } from "@reduxjs/toolkit"
import { doc, updateDoc } from "firebase/firestore"
import { deleteObject, ref } from "@firebase/storage"

import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { IChildCategory } from "@/types/IChildCategory"

const removeChildCategory = createAsyncThunk(
  "/knowledgeCategory/removeChildCategory",
  async ({
    companyId,
    parentCategoryId,
    childCategory,
    urlsToDelete,
    urlsContentToDelete,
  }: {
    companyId: string
    parentCategoryId: string
    childCategory?: IChildCategory[]
    urlsToDelete: string[]
    urlsContentToDelete: string[]
  }) => {
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", parentCategoryId)

    await Promise.all(urlsToDelete.map(async (url) => await deleteObject(ref(storage, url))))

    await Promise.all(urlsContentToDelete.map(async (url) => await deleteObject(ref(storage, url))))

    if (childCategory) {
      updateDoc(dbRef, {
        childCategories: childCategory,
      })
    } else {
      updateDoc(dbRef, {
        childCategories: [],
      })
    }
  },
)

export default removeChildCategory
