import i18n from "i18next"
import Backend from "i18next-http-backend"
import { initReactI18next } from "react-i18next"
import { commonDE, commonEN, commonPL, commonUK } from "@/locales"
import { LANGUAGE } from "@/enums"

export const resources = {
  pl: {
    common: commonPL,
  },
  en: {
    common: commonEN,
  },
  de: {
    common: commonDE,
  },
  uk: {
    common: commonUK,
  },
} as const

// eslint-disable-next-line import/no-named-as-default-member
const language = localStorage.getItem("lang")

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    resources,
    defaultNS: "common",
    fallbackLng: language ? language : LANGUAGE.PL,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
