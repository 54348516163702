import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, getDocs, limit, query, startAfter, where } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { VideoReducer } from "@/redux/store/videos/types"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ITEMS_PER_PAGE } from "@/consts/itemsPerPage"
import {
  addFilteredVideo,
  setFilteredLastElementId,
  setFilteredVideos,
} from "@/redux/store/videos/reducer"

const fetchFilteredVideos = createAsyncThunk(
  "/videos/fetchFilteredVideos",
  async ({ id, filterValue }: { id: string; filterValue?: string }, extra) => {
    let data: VideoReducer[] = []
    const { filterLastElementId } = extra.getState().videos
    const videoRef = collection(db, COLLECTION_NAME.VIDEOS, id, "list")
    if (filterValue && !filterLastElementId) {
      const queryWithFilter = query(
        videoRef,
        limit(ITEMS_PER_PAGE),
        where("tags", "array-contains-any", [filterValue]),
      )
      const querySnapshot = await getDocs(queryWithFilter)
      extra.dispatch(setFilteredLastElementId(querySnapshot.docs[querySnapshot.docs.length - 1]))
      data = querySnapshot.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(setFilteredVideos(data))
      return
    }

    if (filterValue && filterLastElementId) {
      const queryWithFilter = query(
        videoRef,
        limit(ITEMS_PER_PAGE),
        where("tags", "array-contains-any", [filterValue]),
        startAfter(filterLastElementId),
      )
      const querySnapshot = await getDocs(queryWithFilter)
      extra.dispatch(setFilteredLastElementId(querySnapshot.docs[querySnapshot.docs.length - 1]))
      data = querySnapshot.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(addFilteredVideo(data))

      return
    }
  },
)

export default fetchFilteredVideos
