import React from "react"
import { useTranslation } from "react-i18next"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { INPUT_TYPE } from "@/enums/inputType"
import { IUser } from "@/types/IUser"
import { useAppDispatch, useAppSelector, useHandleErrorMessage } from "@/hooks"
import { addUsersToGroup } from "@/redux/store/groups/actions"
import Input from "@/components/Inputs/Input"
import Label from "@/components/Label"
import { IFormAddPersonToGroup } from "@/types/IFormAddPersonToGroup"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import CustomOptions from "@/components/Forms/Groups/CustomOptions"
import { settingsGroupsSelect } from "@/components/Forms/Groups/FormGroup/settingsGroupsSelect"

interface IProps {
  groupId?: string
  groupName?: string
  toggleModal: () => void
  membersList?: IUser[]
}

const FormAddPersonToGroup: React.FC<IProps> = ({
  toggleModal,
  groupId,
  groupName,
  membersList,
}) => {
  const dispatch = useAppDispatch()

  const { users } = useAppSelector((root) => root.users)

  const { admin } = useAppSelector((root) => root.admin)

  const { companyId } = admin

  const { t } = useTranslation("common")

  const { handleErrorMessage } = useHandleErrorMessage()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormAddPersonToGroup>()

  const onSubmit: SubmitHandler<IFormAddPersonToGroup> = async (values) => {
    if (!groupId || !groupName) {
      return
    }

    const { members } = values

    const newMembersId: IUser[] = members.map((member) => ({
      name: member.label,
      userId: member.value,
    }))
    try {
      dispatch(addUsersToGroup({ companyId, groupId, groupName, usersId: newMembersId }))
    } catch (error: unknown) {
      handleErrorMessage(error)
    } finally {
      toggleModal()
    }
  }

  const ids = membersList?.map(({ userId }) => userId)

  const withoutDuplicateList = users.filter(({ userId }) => !ids?.includes(userId))

  const optionsListPeople = withoutDuplicateList.map(({ userId, name }) => ({
    value: userId,
    label: name.fullName,
    position: name.position,
  }))

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col relative w-full h-full">
      <div className="flex flex-col gap-1 pt-8 px-8 ">
        <Label text={t("forms.group_name")} />
        <Input
          value={groupName}
          type={INPUT_TYPE.TEXT}
          id="groupName"
          placeholder={t("forms.placeholderNameGroup")}
          modalInput={true}
          disabled={true}
        />
        <div className="mt-6">
          <Label text={t("forms.add_people")} />
          <Controller
            control={control}
            name="members"
            render={({ field: { onChange, value } }) => (
              <Select
                isSearchable={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Option: CustomOptions,
                }}
                value={value}
                isMulti
                maxMenuHeight={200}
                menuPlacement={"bottom"}
                options={optionsListPeople}
                onChange={(val) => onChange(val)}
                placeholder={t("forms.add_more_people")}
                styles={settingsGroupsSelect}
                className="e-caret-hide"
              />
            )}
          />
        </div>
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.add_person_to_group")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormAddPersonToGroup
