import { UPLOAD_ADMIN_IMAGE_URL } from "@/firebase/rest/uploadAdminImageUrl"

const uploadAdminImage = async (
  companyId: string,
  uploadedImage: File,
  handleError: (error: unknown) => void,
  token: string,
) => {
  const formData = new FormData()

  formData.append("file", uploadedImage)
  formData.append("companyId", companyId)

  try {
    const res = await fetch(UPLOAD_ADMIN_IMAGE_URL, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    const responseJson = await res.json()
    if (responseJson) {
      return responseJson.response.metadata
    }
  } catch (error: unknown) {
    handleError(error)
  }
}

export default uploadAdminImage
