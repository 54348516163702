import React from "react"
import classNames from "classnames"
import { INPUT_TYPE } from "@/enums/inputType"

interface IProps {
  type: INPUT_TYPE
  id: string
  placeholder: string
  register?: any
  setError?: () => void
  modalInput?: boolean
  value?: string
  bgIsTransparent?: boolean
  hidden?: boolean
  noRequired?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  accept?: string
  disabled?: boolean
  titleInput?: boolean
  userSettingsInput?: boolean
  handleChangeSettings?: () => void
  handleUnChangeSettings?: () => void
}

const Input: React.FC<IProps> = ({
  noRequired,
  onChange,
  bgIsTransparent,
  type,
  id,
  register,
  setError,
  placeholder,
  modalInput,
  hidden,
  value,
  disabled,
  titleInput,
  userSettingsInput,
  handleChangeSettings,
  handleUnChangeSettings,
}) => {
  const InputClass = classNames({
    "rounded-lg border border-inputBorder text-greySilver text-sm w-440 h-12 p-2.5 bg-transparent placeholder-greySilver dark:placeholder-greySilver":
      modalInput && bgIsTransparent,
    "rounded-lg border border-inputBorder text-greySilver text-sm w-440 p-2.5 bg-white placeholder-greySilver dark:placeholder-greySilver":
      modalInput && !bgIsTransparent,
    "rounded-lg border-none text-extraLg flex-grow h-full flex p-2.5 bg-transparent outline-none placeholder-greySilver dark:text-greySilver":
      titleInput,
    hidden: hidden,
  })
  const maxLength = 60
  const userSettingsClassNameInput =
    "rounded-lg border border-inputBorder text-greySilver text-sm bg-transparent w-350 h-45 p-2.5 dark:placeholder-greySilver dark:text-greySilver"

  if (register && userSettingsInput) {
    return (
      <input
        {...register(id, {
          required: noRequired ? false : true,
          onChange: setError,
        })}
        type={type}
        id={id}
        onChange={(event) => {
          if (!handleChangeSettings || !handleUnChangeSettings) {
            return
          }
          if (event.target.value === placeholder) {
            handleUnChangeSettings()
          }
          if (event.target.value !== placeholder) {
            handleChangeSettings()
          }
        }}
        className={userSettingsClassNameInput}
        placeholder={placeholder}
        accept={"image/*"}
        minLength={1}
        disabled={disabled}
      />
    )
  }

  if (register) {
    return (
      <input
        {...register(id, {
          required: !noRequired,
          onChange: setError,
        })}
        type={type}
        id={id}
        onChange={(event) => {
          if (!handleChangeSettings || !handleUnChangeSettings) {
            return
          }
          if (event.target.value === placeholder) {
            handleUnChangeSettings()
          }
          if (event.target.value !== placeholder) {
            handleChangeSettings()
          }
        }}
        onClick={setError}
        className={InputClass}
        placeholder={placeholder}
        accept={"image/*"}
        maxLength={maxLength}
      />
    )
  }

  return (
    <input
      type={type}
      value={value}
      id={id}
      onChange={onChange}
      className={InputClass}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}

export default Input
