import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFunctions } from "firebase/functions"
import { httpsCallable } from "@firebase/functions"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { IGroup } from "@/types/IGroup"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

export interface INewUser {
  userId: string
  name: string
  position: string
  phoneNumber: string
  department: string
  groups?: IGroup[]
}

const editUser = createAsyncThunk(
  "/users/editUser",
  async (
    {
      newUserDetails,
      toggleModal,
      companyId,
    }: {
      newUserDetails: INewUser
      toggleModal: () => void
      companyId: string
    },
    extra,
  ) => {
    const settings = extra.getState().settings
    const { departments } = settings.settings

    const isNewDepartment = departments.some((item) => item.name === newUserDetails.department)
    if (!isNewDepartment) {
      const settingsRef = await doc(db, COLLECTION_NAME.COMPANIES, companyId)
      await updateDoc(settingsRef, {
        departments: arrayUnion(newUserDetails.department),
      })
    }

    const functions = getFunctions()
    const updateUser = await httpsCallable(functions, "updateUser")
    const { userId, ...rest } = newUserDetails
    await updateUser({ uid: userId, ...rest })
    toggleModal()
  },
)

export default editUser
