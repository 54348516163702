import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, onSnapshot, Unsubscribe } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { IGroupReducer } from "@/redux/store/groups/types"
import { setGroup } from "@/redux/store/groups/reducer"
import { COLLECTION_NAME } from "@/enums/collectionName"

let unsubscribeFetchAllGroups: Unsubscribe

const fetchAllGroups = createAsyncThunk("/groups/fetchAllGroups", async (id: string, extra) => {
  let data: IGroupReducer[] = []

  unsubscribeFetchAllGroups = onSnapshot(
    collection(db, COLLECTION_NAME.GROUP, id, "list"),
    (doc) => {
      data = doc.docs.map((item) => {
        const { groupName, members } = item.data()
        return {
          id: item.id,
          groupName,
          members,
        }
      })
      extra.dispatch(setGroup(data))
    },
  )
  return []
})

export { unsubscribeFetchAllGroups }

export default fetchAllGroups
