import { flatten } from "lodash"

const splitSingleWord = (word: string) => {
  const charactersArray = word.split("")
  const tagsCharactersArray: string[] = []
  charactersArray.forEach((char, index) => {
    if (index === 0) {
      return tagsCharactersArray.push(char)
    }
    const previousCharacterFromArray = tagsCharactersArray[index - 1]
    const characterToPush = `${previousCharacterFromArray}${char}`
    tagsCharactersArray.push(characterToPush)
  })
  tagsCharactersArray.push(`${tagsCharactersArray[tagsCharactersArray.length - 1]} `)
  return tagsCharactersArray
}

export const returnTransformedTags = (tagsArray: string[]) => {
  const languageTags = tagsArray.map((word) => {
    const wordsArray = word.split(" ")
    if (wordsArray.length === 1) {
      return splitSingleWord(word)
    }

    if (wordsArray.length === 2) {
      const wholeWordTag = splitSingleWord(word)
      // eslint-disable-next-line
      const [_, secondWord] = wordsArray
      const splittedSecondWord = splitSingleWord(secondWord)
      return [...wholeWordTag, ...splittedSecondWord]
    }
    const wholeWordTag = splitSingleWord(word)
    const restWordTags = wordsArray
      .map((innerWord, index) => {
        if (index !== 0) {
          return splitSingleWord(innerWord)
        }
      })
      .filter((array) => array)
    return flatten([...wholeWordTag, ...restWordTags])
  })

  return flatten(languageTags)
}
