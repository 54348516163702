import { updateDoc, doc } from "@firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

const editCategory = createAsyncThunk(
  "/knowledgeCategory/editCategory",
  async ({
    companyId,
    nameUK,
    nameEN,
    nameDE,
    namePL,
    categoryId,
  }: {
    companyId: string
    namePL: string
    nameEN: string
    nameDE: string
    nameUK: string
    categoryId: string
  }) => {
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", categoryId)
    updateDoc(dbRef, {
      nameUK,
      nameEN,
      nameDE,
      namePL,
    })
  },
)

export default editCategory
