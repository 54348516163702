import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import CreatableSelect from "react-select/creatable"
import { IUser } from "@/types/IUser"
import { useAppDispatch, useAppSelector, useHandleErrorMessage } from "@/hooks"
import { IAddUsersToGroup } from "@/types/IAddUsersToGroup"
import Label from "@/components/Label"
import addToGroup from "@/redux/store/users/actions/addToGroup"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import { settingsGroupsSelect } from "@/components/Forms/Groups/FormGroup/settingsGroupsSelect"
import CustomOptions from "@/components/Forms/Groups/CustomOptions"
import Loader from "@/components/Loader"

interface IProps {
  toggleModal: () => void
  membersList?: IUser[]
  clearTargetUsers?: () => void
  resetSelectedRow?: () => void
}

const FormAddToGroup: React.FC<IProps> = ({
  toggleModal,
  membersList,
  clearTargetUsers,
  resetSelectedRow,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")

  const { handleErrorMessage } = useHandleErrorMessage()

  const { admin } = useAppSelector((root) => root.admin)

  const { users, isLoading, error } = useAppSelector((root) => root.users)

  const { groups: groupState } = useAppSelector((state) => state.groups)

  const { companyId } = admin

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IAddUsersToGroup>()

  const onSubmit: SubmitHandler<IAddUsersToGroup> = async (values) => {
    if (!values) {
      return
    }
    const { members: membersForm, groups: newGroup } = values

    const groups = {
      groupId: newGroup.value,
      groupName: newGroup.label,
    }

    const membersIds = membersForm.map(({ value, label }) => ({
      userId: value,
      name: label,
    }))

    const newUserDetails = {
      members: membersIds,
      groups,
      companyId,
    }

    try {
      dispatch(addToGroup({ newUserDetails }))
      if (clearTargetUsers) {
        clearTargetUsers()
      }
      if (resetSelectedRow) {
        resetSelectedRow()
      }
    } catch (err: unknown) {
      handleErrorMessage(err)
    } finally {
      toggleModal()
    }
  }

  const optionsDefaultValue = membersList?.map(({ userId, name }) => ({
    value: userId,
    label: name!,
  }))

  const optionsListGroups = groupState.map(({ groupName, id }) => ({
    value: id,
    label: groupName,
  }))

  const optionsListPeople = users.map(({ userId, name }) => ({
    value: userId,
    label: name.fullName,
    position: name.position,
  }))

  useEffect(() => {
    if (!optionsDefaultValue) {
      return
    }
    setValue("members", optionsDefaultValue)
  }, [])

  if (isLoading) {
    return <Loader />
  }

  if (error.error || !membersList?.length) {
    return <p>Something going wrong</p>
  }
  //obsluga bledow todo

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-between w-full h-500">
      <div className="flex flex-col gap-5 pt-8 px-8 ">
        <Label text={t("forms.select_group")} />
        <Controller
          control={control}
          name="groups"
          render={({ field: { onChange } }) => (
            <CreatableSelect
              components={{
                IndicatorSeparator: () => null,
              }}
              menuPlacement={"bottom"}
              maxMenuHeight={100}
              options={optionsListGroups}
              onChange={(val) => onChange(val)}
              placeholder={"select"}
              className="e-caret-hide"
            />
          )}
        />
        <Label text={t("forms.add_people")} />
        <Controller
          control={control}
          name="members"
          render={({ field: { onChange, value } }) => (
            <Select
              isSearchable={false}
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                Option: CustomOptions,
              }}
              value={value}
              isMulti
              maxMenuHeight={400}
              menuPlacement={"bottom"}
              options={optionsListPeople}
              onChange={(val) => onChange(val)}
              placeholder={"+ add more groups"}
              styles={settingsGroupsSelect}
              className="e-caret-hide"
            />
          )}
        />
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.add_to_group")}
        cancelText={t("forms.cancel")}
        clearTargetUsers={clearTargetUsers}
      />
    </form>
  )
}

export default FormAddToGroup
