import { IRelatedArticle } from "@/types/IRelatedArticles"

const handleSliceRelatedArticle = (relatedArticles: IRelatedArticle[]) => {
  if (!relatedArticles.length) {
    return []
  }

  return relatedArticles.length >= 0 ? relatedArticles.slice(0, 3) : relatedArticles
}

export default handleSliceRelatedArticle
