import { setDoc, doc } from "@firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { v4 as uuid } from "uuid"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

const addCategory = createAsyncThunk(
  "/knowledgeCategory/addCategory",
  async ({
    companyId,
    categoryNames,
  }: {
    companyId: string
    categoryNames: ICategoryFormValues["categoryNames"]
  }) => {
    const newId = uuid()
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", newId)
    setDoc(dbRef, {
      namePL: categoryNames?.pl || "",
      nameEN: categoryNames?.en || "",
      nameDE: categoryNames?.de || "",
      nameUK: categoryNames?.uk || "",
      categoryId: newId,
      childCategories: [],
      documents: [],
    })
  },
)

export default addCategory
