import { createAsyncThunk } from "@reduxjs/toolkit"
import { doc, updateDoc } from "firebase/firestore"
import { deleteObject, ref } from "@firebase/storage"

import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { IChildCategory } from "@/types/IChildCategory"

const removeChildCategories = createAsyncThunk(
  "/knowledgeCategory/removeChildCategories",
  async ({
    companyId,
    toRemoveChildCategory,
    parentCategoryId,
    urlsToDelete,
    urlsContentToDelete,
  }: {
    companyId: string
    toRemoveChildCategory?: IChildCategory[]
    parentCategoryId: string
    urlsToDelete?: string[]
    urlsContentToDelete?: string[]
  }) => {
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", parentCategoryId)
    if (toRemoveChildCategory) {
      updateDoc(dbRef, {
        childCategories: toRemoveChildCategory,
      })
    } else {
      updateDoc(dbRef, {
        childCategories: [],
      })
    }

    if (urlsToDelete) {
      await Promise.all(urlsToDelete.map(async (url) => await deleteObject(ref(storage, url))))
    }
    if (urlsContentToDelete) {
      await Promise.all(
        urlsContentToDelete.map(async (url) => await deleteObject(ref(storage, url))),
      )
    }
  },
)

export default removeChildCategories
