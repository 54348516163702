import { createAsyncThunk } from "@reduxjs/toolkit"
import { doc, updateDoc } from "firebase/firestore"
import { getDownloadURL, ref } from "@firebase/storage"
import { db, storage } from "@/firebase/firebaseConfig"
import { INewSettings } from "@/types/INewSettings"
import { uploadTermsAndConditions } from "@/firebase/firebaseHelpers/settings/uploadTermsAndConditions"
import { uploadCompanyLogo } from "@/firebase/firebaseHelpers/settings/uploadCompanyLogo"
import uploadAdminImage from "@/firebase/firebaseHelpers/user/uploadAdminImage"

const editSettings = createAsyncThunk(
  "/settings/editSettings",
  async ({
    newSettings,
    companyId,
    handleErrorMessage,
    token,
  }: {
    newSettings: INewSettings
    companyId: string
    handleErrorMessage: (error: unknown) => void
    token: string
  }) => {
    let downloadUrlTermsAndConditions
    let downloadUrlCompanyLogo

    const dbRef = doc(db, "companies", companyId)

    if (newSettings.companyLogo && typeof newSettings.companyLogo !== "string") {
      downloadUrlCompanyLogo = await uploadCompanyLogo(newSettings.companyLogo, companyId)
      const res = await uploadAdminImage(
        companyId,
        newSettings.companyLogo,
        handleErrorMessage,
        token,
      )
      downloadUrlCompanyLogo = await getDownloadURL(ref(storage, res.name))
    }

    if (newSettings.termsAndConditions && typeof newSettings.termsAndConditions !== "string") {
      downloadUrlTermsAndConditions = await uploadTermsAndConditions(
        newSettings.termsAndConditions,
        companyId,
      )
    }

    if (downloadUrlCompanyLogo && downloadUrlTermsAndConditions) {
      await updateDoc(dbRef, {
        name: newSettings.companyName,
        settings: {
          logo: downloadUrlCompanyLogo,
          termsAndConditions: downloadUrlTermsAndConditions,
          defaultLanguage: newSettings.defaultLanguage,
          colors: {
            mainColor: newSettings.mainColor ? newSettings.mainColor : "#121cde",
            darkMode: newSettings.darkMode,
          },
        },
      })
      return
    }

    if (downloadUrlTermsAndConditions && !downloadUrlCompanyLogo) {
      await updateDoc(dbRef, {
        name: newSettings.companyName,
        settings: {
          logo: newSettings.companyLogo,
          termsAndConditions: downloadUrlTermsAndConditions,
          defaultLanguage: newSettings.defaultLanguage,
          colors: {
            mainColor: newSettings.mainColor ? newSettings.mainColor : "#121cde",
            darkMode: newSettings.darkMode,
          },
        },
      })
      return
    }

    if (!downloadUrlTermsAndConditions && downloadUrlCompanyLogo) {
      await updateDoc(dbRef, {
        name: newSettings.companyName,
        settings: {
          logo: downloadUrlCompanyLogo,
          termsAndConditions: newSettings.termsAndConditions,
          defaultLanguage: newSettings.defaultLanguage,
          colors: {
            mainColor: newSettings.mainColor ? newSettings.mainColor : "#121cde",
            darkMode: newSettings.darkMode,
          },
        },
      })
      return
    }

    await updateDoc(dbRef, {
      name: newSettings.companyName,
      settings: {
        logo: newSettings.companyLogo,
        termsAndConditions: newSettings.termsAndConditions,
        defaultLanguage: newSettings.defaultLanguage,
        colors: {
          mainColor: newSettings.mainColor ? newSettings.mainColor : "#121cde",
          darkMode: newSettings.darkMode,
        },
      },
    })
  },
)

export default editSettings
