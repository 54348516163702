import { createAsyncThunk } from "@reduxjs/toolkit"
import { doc, updateDoc } from "firebase/firestore"
import i18n from "@/i18n"
import { db } from "@/firebase/firebaseConfig"
import { handleGetDevicesAndLang } from "@/firebase/firebaseHelpers/pushNotifications/handleGetDevicesAndLang"
import handlePushNotifications from "@/firebase/firebaseHelpers/pushNotifications/handlePushNotifications"
import removeNotRegisteredDevice from "@/firebase/firebaseHelpers/user/removeNotRegisteredDevice"
import { ITranslation } from "@/redux/store/articles/types"
import { ERROR_CODE, LANGUAGE } from "@/enums"
import { ARTICLE_STATUS } from "@/enums/articleStatus"
import { COLLECTION_NAME } from "@/enums/collectionName"

interface INewTranslation {
  [key: string]: ITranslation
}

const updateArticleNotifications = createAsyncThunk(
  "/articles/updateArticleNotifications",
  async (
    {
      companyId,
      articleId,
      defaultLanguage,
      handleErrorMessage,
      token,
    }: {
      companyId: string
      articleId: string
      defaultLanguage: string
      handleErrorMessage: (error: unknown) => void
      token: string
    },
    extra,
  ) => {
    const { articles } = extra.getState().articles
    const { users } = extra.getState().users

    const currentArticle = articles.filter(({ uid }) => uid === articleId)[0]
    const { translations } = currentArticle

    const newTranslationsArray: INewTranslation[] = []
    const articleLanguages: string[] = []

    const newTranslations = {}

    Object.keys(translations).forEach((item) => articleLanguages.push(item))

    articleLanguages.forEach((language) => {
      const { isPushNotificationsSent, ...rest } = translations[language as LANGUAGE]
      if (
        translations[language as LANGUAGE].isPushNotificationsSent === false &&
        translations[language as LANGUAGE].status === ARTICLE_STATUS.PUBLISHED
      ) {
        newTranslationsArray.push({
          [language as LANGUAGE]: { isPushNotificationsSent: true, ...rest },
        })
      } else {
        newTranslationsArray.push({
          [language as LANGUAGE]: { isPushNotificationsSent: false, ...rest },
        })
      }
    })

    if (!newTranslationsArray.length) {
      alert(i18n.t("articles.article_must_be_published"))
      return
    }

    newTranslationsArray.forEach((item) => {
      Object.assign(newTranslations, item)
    })

    const articleRef = await doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", articleId)
    await updateDoc(articleRef, {
      pushNotifications: true,
      translations: newTranslations,
    })

    if (!newTranslationsArray.length) {
      return
    }
    const usersForNotifications = handleGetDevicesAndLang(currentArticle, users, defaultLanguage)

    if (!usersForNotifications.length) {
      return
    }
    await Promise.all(
      usersForNotifications.map(async ({ deviceId, lang, userId }) => {
        const response = await handlePushNotifications(
          deviceId,
          articleId,
          lang,
          userId,
          currentArticle.author,
          handleErrorMessage,
          token,
        )
        if (response.error === ERROR_CODE.TOKEN_NOT_REGISTERED) {
          await removeNotRegisteredDevice(deviceId, userId)
        }
      }),
    )
  },
)

export default updateArticleNotifications
