import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, onSnapshot, query, Unsubscribe, where } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { setUsers } from "@/redux/store/users/reducer"
import { ITableHeaderEmployees } from "@/redux/store/users/types"
import { COLLECTION_NAME } from "@/enums/collectionName"

interface IGroup {
  groupName: string
  groupId: string
}

let data: ITableHeaderEmployees[] = []
let unsubscribeFetchAllUsers: Unsubscribe

const fetchAllUsers = createAsyncThunk("/users/fetchAllUsers", async (companyId: string, extra) => {
  const q = await query(
    collection(db, COLLECTION_NAME.USERS),
    where("companyId", "in", [companyId]),
  )

  unsubscribeFetchAllUsers = onSnapshot(q, (doc) => {
    data = doc.docs.map((item) => {
      const {
        name,
        position,
        department,
        phoneNumber,
        groups,
        createdAt,
        previousNotifications,
        deviceId,
        mobileLang,
      } = item.data()
      return {
        name: {
          fullName: name,
          position,
        },
        createdAt,
        userId: item.id,
        department,
        phoneNumber,
        groups: groups?.map((group: IGroup) => ({
          groupName: group.groupName,
          groupId: group.groupId,
        })),
        previousNotifications,
        deviceId,
        mobileLang,
      }
    })
    extra.dispatch(setUsers(data))
  })
})

export { unsubscribeFetchAllUsers }

export default fetchAllUsers
