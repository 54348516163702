import { createAsyncThunk } from "@reduxjs/toolkit"
import { onSnapshot, Unsubscribe } from "@firebase/firestore"
import { doc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { setAdmin } from "@/redux/store/admin/reducer"
import { COLLECTION_NAME } from "@/enums/collectionName"

let unsubscribeFetchAdminInformation: Unsubscribe
let data

const fetchAdminInformation = createAsyncThunk(
  "/admin/fetchAdminInformation",
  async (id: string, extra) => {
    unsubscribeFetchAdminInformation = onSnapshot(doc(db, COLLECTION_NAME.ADMINS, id), (item) => {
      data = item.data()
      if (!data) {
        return
      }
      const { storageId, storageUrl } = data.authorImage
      extra.dispatch(
        setAdmin({
          name: data.name,
          companyId: data.companyId,
          login: data.login,
          email: data.email,
          authorImage: {
            storageId,
            storageUrl,
          },
        }),
      )
    })
  },
)

export { unsubscribeFetchAdminInformation }

export default fetchAdminInformation
