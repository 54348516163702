import { getFunctions } from "firebase/functions"
import { httpsCallable } from "@firebase/functions"
import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const deleteUser = createAsyncThunk(
  "/users/deleteUser",
  async ({ uid, companyId }: { uid: string; companyId: string }, extra) => {
    const state = extra.getState()
    const { users } = state.users

    const targetUsersDetails = users.filter(({ userId }) => userId === uid)

    const [targetUser] = targetUsersDetails

    const groupsFromUsers = targetUser.groups!.map((group) => ({
      groupId: group.groupId,
      groupName: group.groupName,
    }))

    await Promise.all(
      groupsFromUsers.map(async (group) => {
        if (!group) {
          return
        }
        const groupRef = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", group.groupId)
        await updateDoc(groupRef, {
          members: arrayRemove({
            userId: targetUser.userId,
            name: targetUser.name.fullName,
          }),
        })
      }),
    )

    const functions = await getFunctions()
    const deleteAction = await httpsCallable(functions, "deleteUser")
    await deleteAction({ uid })
  },
)

export default deleteUser
