import { createSlice } from "@reduxjs/toolkit"
import { IArticlesReducer } from "@/redux/store/articles/types"
import { IError } from "@/redux/store/groups/types"
import { ARTICLES } from "@/consts/reducer"
import fetchAllArticles from "@/redux/store/articles/actions/fetchAllArticles"
import deleteArticle from "@/redux/store/articles/actions/deleteArticle"
import deleteManyArticles from "@/redux/store/articles/actions/deleteManyArticles"
import addArticle from "@/redux/store/articles/actions/addArticle"
import editArticle from "@/redux/store/articles/actions/editArticle"
import updateArticleNotifications from "@/redux/store/articles/actions/updateArticleNotifications"

export const initialArticles: IArticlesReducer[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  articles: initialArticles,
  isLoading: false,
  error: initialError,
}

const articlesSlice = createSlice({
  name: ARTICLES,
  initialState,
  reducers: {
    setArticles: (state, action) => {
      state.articles = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllArticles.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchAllArticles.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchAllArticles.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(addArticle.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(addArticle.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addArticle.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(editArticle.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(editArticle.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(editArticle.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(updateArticleNotifications.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(updateArticleNotifications.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(updateArticleNotifications.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(deleteArticle.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(deleteArticle.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(deleteArticle.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(deleteManyArticles.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(deleteManyArticles.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(deleteManyArticles.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const { setArticles } = articlesSlice.actions

export default articlesSlice.reducer
