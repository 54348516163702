import { darkModeTheme } from "@/theme/darkModeTheme"

interface ITheme {
  [key: string]: string
}

const applyDarkMode = (theme: ITheme) => {
  const root = document.getElementById("root")
  const modalRoot = document.getElementById("modal-root")
  document.documentElement.classList.add("dark")
  if (!root || !modalRoot) {
    return
  }
  Object.keys(theme).forEach((cssVariable) => {
    root.style.setProperty(cssVariable, theme[cssVariable])
    modalRoot.style.setProperty(cssVariable, theme[cssVariable])
  })
}

const setApplyDarkMode = () => applyDarkMode(darkModeTheme)

export default setApplyDarkMode
