import React from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import Select from "react-select"
import { useTranslation } from "react-i18next"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { INPUT_TYPE } from "@/enums/inputType"
import { IGroups } from "@/types/IGroups"
import validationGroupSchema from "@/validationSchema/validationGroupSchema"
import { useAppDispatch, useAppSelector, useHandleErrorMessage } from "@/hooks"
import { addNewGroup } from "@/redux/store/groups/actions"
import Input from "@/components/Inputs/Input"
import Label from "@/components/Label"
import { ITableHeaderEmployees } from "@/redux/store/users/types"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import { settingsGroupsSelect } from "@/components/Forms/Groups/FormGroup/settingsGroupsSelect"
import CustomOptions, { SelectInputOptionInterface } from "@/components/Forms/Groups/CustomOptions"

interface IProps {
  toggleModal: () => void
}

const FormGroup: React.FC<IProps> = ({ toggleModal }) => {
  const { t } = useTranslation("common")

  const { handleErrorMessage } = useHandleErrorMessage()

  const { users } = useAppSelector((root) => root.users)

  const { admin } = useAppSelector((root) => root.admin)

  const { companyId } = admin

  const dispatch = useAppDispatch()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IGroups>({
    resolver: yupResolver(validationGroupSchema),
    mode: "all",
  })

  const onSubmit: SubmitHandler<IGroups> = async (data) => {
    const { members } = data

    let newUsersId: string[] = []
    let newUsers: ITableHeaderEmployees[] = []

    if (members) {
      newUsersId = members.map(({ value }) => value)

      newUsers = users.filter((item) => newUsersId.includes(item.userId))
    }
    try {
      dispatch(
        addNewGroup({
          companyId,
          groupName: data.groupName,
          members: newUsers,
        }),
      )
      toggleModal()
    } catch (error: unknown) {
      handleErrorMessage(error)
    }
  }

  const optionsListPeople: readonly SelectInputOptionInterface[] = users.map(
    ({ name, userId }) => ({
      value: userId,
      label: name.fullName,
      position: name.position,
    }),
  )

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full h-full flex-grow justify-between"
    >
      <div className="flex flex-col gap-2 pt-8 px-8 h-300 ">
        <label className="text-spunPearl text-xs">{t("forms.name")}</label>
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="groupName"
          placeholder={t("forms.placeholderNameGroup")}
          modalInput={true}
        />
        <div className="text-red text-sm">{errors.groupName?.message}</div>
        <div className="mt-6">
          <Label text={t("forms.add_people")} />
          <Controller
            control={control}
            name="members"
            render={({ field: { onChange } }) => (
              <Select
                isSearchable={true}
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                  Option: CustomOptions,
                }}
                isMulti
                placeholder={t("groups.click_to_add_person")}
                options={optionsListPeople}
                styles={settingsGroupsSelect}
                menuPlacement={"bottom"}
                onChange={(val) => onChange(val)}
                className="e-caret-hide"
              />
            )}
          />
        </div>
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.create_group")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormGroup
