import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import CreatableSelect from "react-select/creatable"
import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import Input from "@/components/Inputs/Input"
import { INPUT_TYPE } from "@/enums/inputType"
import { IEditUser } from "@/types/IEditUser"
import { useAppDispatch, useAppSelector } from "@/hooks"
import Label from "@/components/Label"
import validationEditSchema from "@/validationSchema/validationEditSchema"
import editUser from "@/redux/store/users/actions/editUser"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import Loader from "@/components/Loader"
import { ERROR_CODE } from "@/enums"
import { clearError } from "@/redux/store/users/reducer"

interface IProps {
  toggleModal: () => void
  targetUserId: string
}

const FormEditUser: React.FC<IProps> = ({ toggleModal, targetUserId }) => {
  const dispatch = useAppDispatch()

  const { users, error, isLoading } = useAppSelector((root) => root.users)
  const { admin } = useAppSelector((root) => root.admin)
  const { companyId } = admin
  const { settings } = useAppSelector((root) => root.settings)

  const { departments: departmentsList } = settings

  const userInfo = users.filter(({ userId }) => userId === targetUserId)
  const [user] = userInfo
  const { name: userName, userId: userUid, phoneNumber, groups: userGroup } = user

  const [userInformation, setUserInformation] = useState({
    name: userName.fullName,
    position: userName.position,
    department: user.department,
    userId: userUid,
    phoneNumber,
    groups: userGroup,
  })

  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<IEditUser>({
    resolver: yupResolver(validationEditSchema),
    mode: "all",
  })
  const { t } = useTranslation("common")

  const defaultDepartment = {
    value: userInformation.department,
    label: userInformation.department,
  }

  useEffect(() => {
    if (!userInformation) {
      return
    }
    const { department, groups: groupList, ...rest } = userInformation
    Object.entries(rest).forEach(([name, value]: any) => setValue(name, value))
    setValue("department", defaultDepartment)
  }, [])

  const onSubmit: SubmitHandler<IEditUser> = async (data) => {
    if (!data) {
      return
    }

    const { userId, department, groups: listGroup, ...rest } = data

    const newDepartment = department.value
    const newUserDetails = {
      userId,
      groups: userInformation.groups,
      department: newDepartment,
      ...rest,
    }
    await dispatch(editUser({ newUserDetails, toggleModal, companyId }))
  }

  const handleError = (errCode: ERROR_CODE) => {
    switch (errCode) {
      case ERROR_CODE.NUMBER_TOO_SHORT:
        return <div className="text-red text-sm">{t("forms.the_number_is_too_short")}</div>
      case ERROR_CODE.NUMBER_TOO_LONG:
        return <div className="text-red text-sm">{t("forms.the_number_is_too_long")}</div>
      case ERROR_CODE.USER_WITH_THIS_NUMBER_EXISTS:
        return <div className="text-red text-sm">{t("forms.user_already_exists")}</div>
      case ERROR_CODE.INVALID_FORMAT_NUMBER:
        return <div className="text-red text-sm">{t("forms.invalid_number_format")}</div>
      default:
        return
    }
  }

  const departmentsWithoutDuplicate = departmentsList.filter((name) => name)

  const optionsListDepartment = departmentsWithoutDuplicate.map((item) => ({
    value: item,
    label: item,
  }))

  if (isLoading) {
    return <Loader />
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col w-full h-full flex-grow">
      <div className="flex flex-col gap-2 pt-8 px-8 ">
        <Label text={t("groups.name")} />
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="name"
          placeholder={t("forms.placeholderNameGroup")}
          modalInput={true}
        />
        <div className="text-red text-sm">{errors.name?.message}</div>
        <Label text={t("forms.position")} />
        <Input
          register={register}
          type={INPUT_TYPE.TEXT}
          id="position"
          placeholder={t("forms.placeholderPosition")}
          modalInput={true}
        />
        <div className="text-red text-sm">{errors.position?.message}</div>
        <Label text={t("forms.department")} />
        <Controller
          rules={{ required: true }}
          control={control}
          name="department"
          render={({ field: { onChange } }) => (
            <CreatableSelect
              defaultValue={defaultDepartment as any}
              options={optionsListDepartment}
              onChange={(val) => onChange(val)}
              placeholder={t("forms.placeholderDepartment")}
              className="text-gray-900 text-sm w-440"
              styles={{
                control: (base) => ({
                  ...base,
                  color: "grey",
                  backgroundColor: "var(--white)",
                  width: "440px",
                  border: `1px "var(--inputBorder)" solid`,
                }),
              }}
            />
          )}
        />
        <div className="text-red text-sm">
          {errors.department?.label?.message} {errors.department?.value?.message}
        </div>
        <Label text={t("forms.phoneNumber")} />
        <Input
          onChange={() => dispatch(clearError())}
          register={register}
          type={INPUT_TYPE.TEXT}
          id="phoneNumber"
          placeholder={t("forms.placeholderPhoneNumber")}
          modalInput={true}
        />
        {error && error.error && handleError(error.error.message as ERROR_CODE)}
        <div className="text-red text-sm">{errors.phoneNumber?.message}</div>
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.edit_user")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormEditUser
