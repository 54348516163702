import { createSlice } from "@reduxjs/toolkit"
import { IAdminReducer } from "@/redux/store/admin/types"
import { IError } from "@/redux/store/groups/types"
import { ADMIN } from "@/consts/reducer"
import fetchAdminInformation from "@/redux/store/admin/actions/fetchAdminInformation"
import editAdminInformation from "@/redux/store/admin/actions/editAdminInformation"

export const initialAdmin: IAdminReducer = {
  name: "",
  companyId: "",
  login: "",
  email: "",
  authorImage: {
    storageId: "",
    storageUrl: "",
  },
}

const initialError: IError = {
  error: null,
}

const initialState = {
  admin: initialAdmin,
  isLoading: false,
  error: initialError,
}

const adminSlice = createSlice({
  name: ADMIN,
  initialState,
  reducers: {
    setAdmin: (state, action) => {
      state.admin = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAdminInformation.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchAdminInformation.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchAdminInformation.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(editAdminInformation.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(editAdminInformation.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(editAdminInformation.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const { setAdmin } = adminSlice.actions

export default adminSlice.reducer
