import React from "react"
import { motion } from "framer-motion"
import ReactDOM from "react-dom"

const bounceTransition = {
  y: {
    duration: 0.4,
    yoyo: Infinity,
    ease: "easeOut",
    repeatDelay: 0.8,
  },
  backgroundColor: {
    duration: 0.4,
    yoyo: Infinity,
    repeatDelay: 0.8,
  },
}

const bounceTransitionSecond = {
  y: {
    duration: 0.4,
    yoyo: Infinity,
    ease: "easeOut",
    delay: 0.2,
    repeatDelay: 0.8,
  },
  backgroundColor: {
    duration: 0.4,
    yoyo: Infinity,
    repeatDelay: 0.8,
  },
}

const bounceTransitionThird = {
  y: {
    duration: 0.4,
    yoyo: Infinity,
    delay: 0.4,
    repeatDelay: 0.8,
  },
  backgroundColor: {
    duration: 0.4,
    yoyo: Infinity,
    repeatDelay: 0.8,
  },
}

const Loader = () => {
  return ReactDOM.createPortal(
    <div
      id="modal-container"
      className="justify-center items-center flex w-screen h-screen fixed inset-0  z-50 outline-none backdrop-blur-sm backdrop-brightness-50"
    >
      <div className="w-screen h-screen z-50 inset-0  flex flex-col backdrop-blur-sm backdrop-brightness-50 rounded-2xl bg-white w-full">
        <div className="flex  flex-row gap-4 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <motion.div
            transition={bounceTransition}
            animate={{
              y: ["100%", "-100%"],
              backgroundColor: ["#626467", "#121cde"],
            }}
            className=" rounded-full black   w-5 h-5 "
          ></motion.div>
          <motion.div
            transition={bounceTransitionSecond}
            animate={{
              y: ["100%", "-100%"],
              backgroundColor: ["#626467", "#121cde"],
            }}
            className="  rounded-full  bg-purple w-5 h-5 "
          ></motion.div>
          <motion.div
            transition={bounceTransitionThird}
            animate={{
              y: ["100%", "-100%"],
              backgroundColor: ["#626467", "#121cde"],
            }}
            className="  rounded-full  bg-red w-5 h-5  "
          ></motion.div>
        </div>
      </div>
    </div>,
    document.getElementById("modal-root")!,
  )
}

export default Loader
