import { createAsyncThunk } from "@reduxjs/toolkit"
import { addDoc, collection, getDocs, query, where } from "@firebase/firestore"
import { doc, Timestamp, updateDoc } from "firebase/firestore"
import { isEmpty, isEqual, xorWith } from "lodash"
import { deleteObject, getDownloadURL, ref } from "@firebase/storage"
import { db, storage } from "@/firebase/firebaseConfig"
import { uploadContentState } from "@/firebase/firebaseHelpers/articles/uploadContentState"
import { handleEditArticleGetDevicesAndLang } from "@/firebase/firebaseHelpers/pushNotifications/handleGetDevicesAndLang"
import handlePushNotifications from "@/firebase/firebaseHelpers/pushNotifications/handlePushNotifications"
import removeNotRegisteredDevice from "@/firebase/firebaseHelpers/user/removeNotRegisteredDevice"
import { returnTransformedTags } from "@/helpers/handleMakeTags"
import handleSliceRelatedArticle from "@/helpers/slicedRelatedArticle"
import { IArticlesReducer } from "@/redux/store/articles/types"
import { IRelatedArticle } from "@/types/IRelatedArticles"
import { IGroup } from "@/types/IGroup"
import { IFirebaseFile } from "@/types/IFirebaseFile"
import { ARTICLE_STATUS } from "@/enums/articleStatus"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ERROR_CODE, LANGUAGE } from "@/enums"

interface INewArticleDetails {
  status: ARTICLE_STATUS
  title?: string
  groups: IGroup[]
  promoted: boolean
  pushNotifications: boolean
  featureImage?: IFirebaseFile
  htmlContentState: string
  markdownContentState: string
  currentEditLanguage: string
  authorImage: IFirebaseFile
  imagesGallery?: IFirebaseFile[]
  video?: IFirebaseFile[]
}

const editArticle = createAsyncThunk(
  "/articles/editArticle",
  async (
    {
      companyId,
      newArticleDetails,
      articleId,
      defaultLanguage,
      handleErrorMessage,
      token,
    }: {
      companyId: string
      newArticleDetails: INewArticleDetails
      articleId?: string
      defaultLanguage: string
      handleErrorMessage: (error: unknown) => void
      token: string
    },
    extra,
  ) => {
    const {
      currentEditLanguage,
      title,
      status,
      markdownContentState,
      featureImage,
      htmlContentState,
    } = newArticleDetails

    const { articles } = extra.getState().articles
    const { groups: allGroups } = extra.getState().groups
    const { users } = extra.getState().users

    const membersIds: string[] = []
    let isTitleChanged = false
    let isGroupChanged = false

    if (newArticleDetails.groups.length) {
      const groupIds = newArticleDetails.groups.map(({ groupId }) => groupId)
      const filteredGroups = allGroups.filter(({ id }) => groupIds.includes(id))
      filteredGroups.forEach(({ members }) =>
        members.forEach(({ userId }) => membersIds.push(userId)),
      )
    }

    const currentArticle = articles.filter(({ uid }) => uid === articleId)
    const isFeaturedImageSame =
      featureImage?.storageUrl === currentArticle[0].featuredImage?.storageUrl
    let newRelatedArticlesIds = currentArticle[0].relatedArticlesId

    if (
      currentArticle[0].translations[currentEditLanguage as LANGUAGE] &&
      currentArticle[0].translations[currentEditLanguage as LANGUAGE].title !== title
    ) {
      isTitleChanged = true
    }

    let isNotificationSent = false

    currentArticle.forEach(({ translations }) => {
      Object.entries(translations).forEach((item) => {
        isNotificationSent = item[1].notificationsSent
      })
    })

    if (!isNotificationSent && status === ARTICLE_STATUS.PUBLISHED) {
      const notificationsRef = await collection(
        db,
        COLLECTION_NAME.NOTIFICATIONS,
        companyId,
        "list",
      )
      await addDoc(notificationsRef, {
        articleId,
        author: currentArticle[0].author,
        authorImage: {
          storageId: newArticleDetails.authorImage.storageId,
          storageUrl: newArticleDetails.authorImage.storageUrl,
        },
        newUsers: membersIds,
        previousUsers: [],
        groups: newArticleDetails.groups,
        createdAt: Timestamp.fromDate(new Date()),
        title,
        language: currentEditLanguage,
      })
    }

    if (isNotificationSent && status === ARTICLE_STATUS.PUBLISHED) {
      const newMembersIds: string[] = []
      if (!isEmpty(xorWith(newArticleDetails.groups, currentArticle[0].groups, isEqual))) {
        isGroupChanged = true
        const filteredArray = newArticleDetails.groups.filter(({ groupId }) =>
          currentArticle[0].groups.find((group) => groupId !== group.groupId),
        )

        const idsGroups = filteredArray.map(({ groupId }) => groupId)
        const filteredGroups = allGroups.filter(({ id }) => idsGroups.includes(id))
        filteredGroups.forEach(({ members }) =>
          members.forEach(({ userId }) => newMembersIds.push(userId)),
        )

        const notificationsRef = await collection(
          db,
          COLLECTION_NAME.NOTIFICATIONS,
          companyId,
          "list",
        )
        await addDoc(notificationsRef, {
          articleId,
          author: currentArticle[0].author,
          authorImage: {
            storageId: newArticleDetails.authorImage.storageId,
            storageUrl: newArticleDetails.authorImage.storageUrl,
          },
          newUsers: newMembersIds,
          previousUsers: [],
          groupIds: idsGroups,
          groups: filteredArray,
          createdAt: Timestamp.fromDate(new Date()),
          title,
          language: currentEditLanguage,
        })
      }
    }
    let newRelatedArticles: IRelatedArticle[] = []
    let relatedArticlesList: IRelatedArticle[] = []

    if (newArticleDetails.groups.length) {
      const groupIds = newArticleDetails.groups.map(({ groupId }) => groupId)

      const articlesWithSameGroup = articles.filter(({ groups: groupLists }) =>
        groupLists.find(({ groupId }) => groupIds.includes(groupId)),
      )

      const filterArticlesWithSameGroup = articlesWithSameGroup.filter(
        ({ uid }) => uid !== articleId,
      )

      const idsArticlesWithSameGroup = articlesWithSameGroup.map(({ uid }) => uid)
      newRelatedArticlesIds = [...newRelatedArticlesIds, ...idsArticlesWithSameGroup]

      if (filterArticlesWithSameGroup) {
        newRelatedArticles = filterArticlesWithSameGroup.map(
          ({
            tagsUK,
            tagsPL,
            tagsDE,
            tagsEN,
            createdAt,
            relatedArticlesId,
            relatedArticles: relatedArticle,
            promoted: promotedArticle,
            pushNotifications: pushNotificationsArticle,
            translations: translationsArticle,
            uid,
            ...rest
          }) => ({
            articleId: uid,
            createdAt: Timestamp.fromDate(new Date(createdAt)),
            translations: {
              [LANGUAGE.PL]: {
                title: translationsArticle.pl?.title ? translationsArticle.pl.title : "",
              },
              [LANGUAGE.EN]: {
                title: translationsArticle.en?.title ? translationsArticle.en.title : "",
              },
              [LANGUAGE.UK]: {
                title: translationsArticle.uk?.title ? translationsArticle.uk.title : "",
              },
              [LANGUAGE.DE]: {
                title: translationsArticle.de?.title ? translationsArticle.de.title : "",
              },
            },
            ...rest,
          }),
        )
        relatedArticlesList = handleSliceRelatedArticle(newRelatedArticles)
      }
    }

    const parsedTittle = returnTransformedTags([title!.toLowerCase()])
    const parsedAuthor = returnTransformedTags([currentArticle[0].author.toLowerCase()])

    const newTags = [...parsedTittle, ...parsedAuthor]

    const fileNameMarkDown = `${companyId}/articles/${title}-${currentEditLanguage}.md`
    const urlMarkDown = await uploadContentState(markdownContentState, fileNameMarkDown)

    const fileNameHtml = `${companyId}/articles/${title}-${currentEditLanguage}.html`
    const urlHtml = await uploadContentState(htmlContentState, fileNameHtml)

    const languageToUpperCase = `tags${currentEditLanguage.toUpperCase()}`

    let updatedArticleWithNewTags

    currentArticle.forEach((article) => {
      if (article[languageToUpperCase]) {
        updatedArticleWithNewTags = { ...article }
        updatedArticleWithNewTags[languageToUpperCase] = newTags
        return updatedArticleWithNewTags
      }
    })

    // sprawdza czy jest juz jakis artykul promoted , jak jest to zmienia go na false

    if (newArticleDetails.promoted) {
      const q = query(
        collection(db, COLLECTION_NAME.ARTICLES, companyId, "list"),
        where("promoted", "==", true),
      )
      const querySnapshot = await getDocs(q)

      let idArticleForUpdate = ""

      querySnapshot.docs.forEach((item) => (idArticleForUpdate = item.id))
      if (idArticleForUpdate) {
        const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", idArticleForUpdate)

        await updateDoc(articleRef, {
          promoted: false,
        })
      }
    }

    if (updatedArticleWithNewTags) {
      const {
        uid,
        featuredImage,
        author,
        authorImage,
        promoted,
        groups,
        pushNotifications,
        createdAt,
        translations,
        type,
        relatedArticles,
        relatedArticlesId,
        ...rest
      } = updatedArticleWithNewTags as IArticlesReducer
      const editedArticle = {
        author,
        authorImage: {
          storageId: newArticleDetails.authorImage.storageId,
          storageUrl: newArticleDetails.authorImage.storageUrl,
        },
        createdAt: Timestamp.fromDate(new Date(createdAt)),
        featuredImage: {
          storageId: featureImage?.storageId,
          storageUrl: featureImage?.storageUrl,
        },
        promoted: newArticleDetails.promoted,
        groups: newArticleDetails.groups,
        pushNotifications: newArticleDetails.pushNotifications,
        type,
        relatedArticles: relatedArticlesList,
        relatedArticlesId: newRelatedArticlesIds,
        [languageToUpperCase]: newTags,
        translations: {
          ...translations,
          [currentEditLanguage]: {
            isPushNotificationsSent:
              newArticleDetails.pushNotifications &&
              newArticleDetails.status === ARTICLE_STATUS.PUBLISHED
                ? newArticleDetails.pushNotifications
                : false,
            title,
            status,
            notificationsSent: newArticleDetails.status === ARTICLE_STATUS.PUBLISHED,
            content: {
              contentStorageId: fileNameMarkDown,
              contentUrl: urlMarkDown,
              contentHtmlUrl: urlHtml,
              contentHtmlStorageId: fileNameHtml,
            },
            imagesGallery: newArticleDetails.imagesGallery?.length
              ? newArticleDetails.imagesGallery
              : [],
            video: newArticleDetails.video?.length ? newArticleDetails.video : [],
          },
        },
        ...rest,
      }

      if (
        status === ARTICLE_STATUS.PUBLISHED &&
        newArticleDetails.pushNotifications &&
        (!currentArticle[0].translations[currentEditLanguage as LANGUAGE] ||
          !currentArticle[0].translations[currentEditLanguage as LANGUAGE].isPushNotificationsSent)
      ) {
        const usersForNotifications = handleEditArticleGetDevicesAndLang(
          editedArticle as any,
          users,
          currentEditLanguage,
          defaultLanguage,
        )
        await Promise.all(
          usersForNotifications.map(async ({ deviceId, lang, userId }) => {
            const response = await handlePushNotifications(
              deviceId,
              articleId!,
              lang,
              userId,
              currentArticle[0].author,
              handleErrorMessage,
              token,
            )
            if (response.error === ERROR_CODE.TOKEN_NOT_REGISTERED) {
              await removeNotRegisteredDevice(deviceId, userId)
            }
          }),
        )
      }

      // jezeli zmienil sie tytul lub grupy zmieniam w artykulach ktore uzyly tego artykulu w  related articles

      if (isTitleChanged || isGroupChanged || !isFeaturedImageSame) {
        const articleCollection = collection(db, COLLECTION_NAME.ARTICLES, companyId, "list")
        const articleQuery = query(
          articleCollection,
          where("relatedArticlesId", "array-contains-any", [articleId]),
        )
        const querySnapshot = await getDocs(articleQuery)
        const data = querySnapshot.docs.map((item) => {
          return { uid: item.id, ...item.data() } as IArticlesReducer
        })

        const changedRelatedArticle = data.map((article: IArticlesReducer) => ({
          ...article,
          relatedArticles: article.relatedArticles.map((related: IRelatedArticle) =>
            related.articleId === articleId
              ? {
                  articleId,
                  author: editedArticle.author,
                  authorImage: editedArticle.authorImage,
                  createdAt: editedArticle.createdAt,
                  featuredImage: editedArticle.featuredImage,
                  groups: editedArticle.groups,
                  translations: editedArticle.translations,
                  type: editedArticle.type,
                }
              : related,
          ),
        }))

        if (changedRelatedArticle.length) {
          await Promise.all(
            changedRelatedArticle.map(async (newEditedArticles) => {
              const articleWithRelatedUpdate = await doc(
                db,
                COLLECTION_NAME.ARTICLES,
                companyId,
                "list",
                newEditedArticles.uid!,
              )
              await updateDoc(articleWithRelatedUpdate, {
                ...newEditedArticles,
              })
            }),
          )
        }
      }

      const articleRef = await doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", articleId!)
      await updateDoc(articleRef, {
        ...editedArticle,
      })

      if (isTitleChanged) {
        const titleArticle = currentArticle[0].translations[currentEditLanguage as LANGUAGE].title
        const articlePath = `${companyId}/articles/${titleArticle}-${currentEditLanguage}.md`
        await getDownloadURL(ref(storage, articlePath)).then(
          async () => await deleteObject(ref(storage, articlePath)),
        )
      }
    }
  },
)

export default editArticle
