import { initializeApp } from "firebase/app"
import { getAuth } from "@firebase/auth"
import { getFirestore } from "@firebase/firestore"
import { getFunctions } from "firebase/functions"
import { getStorage } from "@firebase/storage"
import { getMessaging, getToken } from "@firebase/messaging"

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIRE_API_KEY,
  authDomain: process.env.REACT_APP_FIRE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIRE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIRE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIRE_APP_ID,
}

const app = initializeApp(firebaseConfig)

export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
export const functions = getFunctions(app)
export const messaging = getMessaging(app)
