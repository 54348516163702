const handleTags = (languages: string[], tags: string[]) => {
  const languagesLength = languages.length

  let newValue = {}

  for (let i = 0; i < languagesLength; i++) {
    newValue = {
      ...newValue,
      [`tags${languages[i].toUpperCase()}`]: tags,
    }
  }
  return newValue
}

export default handleTags
