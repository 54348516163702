import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, onSnapshot, Unsubscribe } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"

import { COLLECTION_NAME } from "@/enums/collectionName"
import { ICategory } from "@/types/ICategory"
import { setCategory } from "../reducer"

let unsubscribeFetchAllCategories: Unsubscribe

const fetchAllCategories = createAsyncThunk(
  "/knowledgeCategory/fetchAllCategories",
  async (id: string, extra) => {
    let data: ICategory[] = []

    unsubscribeFetchAllCategories = onSnapshot(
      collection(db, COLLECTION_NAME.CATEGORIES, id, "list"),
      (doc) => {
        data = doc.docs.map((item) => {
          const { categoryId, namePL, nameEN, nameDE, nameUK, childCategories, documents } =
            item.data()
          return {
            id: item.id,
            categoryId,
            childCategories,
            documents,
            nameDE,
            nameEN,
            namePL,
            nameUK,
          }
        })
        extra.dispatch(setCategory(data))
      },
    )
    return []
  },
)

export { unsubscribeFetchAllCategories }

export default fetchAllCategories
