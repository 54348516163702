import * as Yup from "yup"
import i18n from "@/i18n"

const validationResetPassword = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t("sign_in.required"))
    .min(8)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!?$()`@#\$%\^&\*])(?=.{8,})/,
      i18n.t("sign_in.reset_password_validation"),
    ),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password")],
    i18n.t("sign_in.password_must_match"),
  ),
})

export default validationResetPassword
