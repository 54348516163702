import { baseTheme } from "@/theme/baseTheme"

interface ITheme {
  [key: string]: string
}

const applyBaseTheme = (theme: ITheme) => {
  const root = document.getElementById("root")
  const modalRoot = document.getElementById("modal-root")
  document.documentElement.classList.remove("dark")
  if (!root || !modalRoot) {
    return
  }
  Object.keys(theme).forEach((cssVariable) => {
    root.style.setProperty(cssVariable, theme[cssVariable])
    modalRoot.style.setProperty(cssVariable, theme[cssVariable])
  })
}

const setApplyBaseTheme = () => applyBaseTheme(baseTheme)

export default setApplyBaseTheme
