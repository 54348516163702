import { StylesConfig } from "react-select"

export const settingsGroupsSelect: StylesConfig = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (styles) => ({
    top: "70px",
    minHeight: "120px",
    borderRadius: "10px",
    padding: 0,
    position: "absolute",
    width: "440px",
    overflowY: "auto",
    zIndex: 9999,
    fontSize: "13px",
  }),

  control: (styles, state) => ({
    ...styles,
    backgroundColor: "var(--white)",
    color: "white",
    width: "440px",
    minHeight: "60px",
    maxHeight: "150px",
    display: "flex",
    alignItems: "flex-start",
    fontSize: "13px",
    borderColor: "",
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      cursor: "default",
      backgroundColor: isFocused ? "#d6d6d6" : "white",
    }
  },
  valueContainer: (provided) => ({
    ...provided,
    color: "white",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    maxHeight: "80px",
    overflowY: "auto",
  }),
  multiValue: (styles, data: any) => ({
    ...styles,
    backgroundColor: "var(--primary)",
    color: "var(--white)",
    width: "124px",
    height: "30px",
    display: "flex",
    borderRadius: "4px",
    alignItems: "center",
    "&:hover": {
      height: `${data.data.label.length > 16 ? "auto" : "30"}`,
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: "var(--white)",
    width: "100%",
    textAlign: "center",
    font: "11px IBM Plex Sans",
    fontWeight: 500,
    "&:hover": {
      whiteSpace: "pre-line",
    },
  }),

  multiValueRemove: (styles) => ({
    ...styles,
  }),
}
