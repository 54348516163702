import { getDownloadURL, ref, uploadBytes } from "@firebase/storage"
import { storage } from "@/firebase/firebaseConfig"

export async function uploadContentState(file: string, fileName: string) {
  return new Promise(function (resolve, reject) {
    const storageRef = ref(storage, fileName)

    const parsedFile = JSON.stringify(file)

    const parsedText = new Blob([JSON.parse(parsedFile)], {
      type: "text/markdown",
    })

    uploadBytes(storageRef, parsedText).then((snapshot) => {
      const url = getDownloadURL(snapshot.ref)
      if (!url) {
        reject()
      }
      resolve(url)
    })
  })
}
