import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, limit, onSnapshot, query, startAfter, Unsubscribe } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { VideoReducer } from "@/redux/store/videos/types"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ITEMS_PER_PAGE } from "@/consts/itemsPerPage"
import { addVideo, setLastElementId, setVideos } from "@/redux/store/videos/reducer"

let unsubscribeFetchVideos: Unsubscribe

const fetchVideos = createAsyncThunk(
  "/videos/fetchVideos",
  async ({ id }: { id: string }, extra) => {
    let data: VideoReducer[] = []
    const { lastElementId } = extra.getState().videos
    const videoRef = collection(db, COLLECTION_NAME.VIDEOS, id, "list")
    if (!lastElementId) {
      const queryVideo = query(videoRef, limit(ITEMS_PER_PAGE))
      unsubscribeFetchVideos = onSnapshot(queryVideo, (doc) => {
        extra.dispatch(setLastElementId(doc.docs[doc.docs.length - 1]))
        data = doc.docs.map((item) => {
          const { storageId, storageUrl, tags } = item.data()
          return {
            id: item.id,
            storageId,
            storageUrl,
            tags,
          }
        })
        extra.dispatch(setVideos(data))
      })
      return
    }
    const queryVideoNextPage = query(videoRef, limit(ITEMS_PER_PAGE), startAfter(lastElementId))
    unsubscribeFetchVideos = onSnapshot(queryVideoNextPage, (doc) => {
      extra.dispatch(setLastElementId(doc.docs[doc.docs.length - 1]))
      data = doc.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(addVideo(data))
    })
  },
)

export { unsubscribeFetchVideos }

export default fetchVideos
