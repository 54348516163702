import { createAsyncThunk } from "@reduxjs/toolkit"
import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const removeUserFromGroup = createAsyncThunk(
  "/groups/removeUserFromGroup",

  async (
    {
      companyId,
      groupId,
      userId,
      groupName,
    }: {
      companyId: string
      groupId: string
      userId: string
      groupName: string
    },
    extra,
  ) => {
    const state = extra.getState().users
    const { users } = state
    const deleteUser = users.find((item) => item.userId === userId)

    if (!deleteUser) {
      return
    }

    const { fullName } = deleteUser.name

    const user = doc(db, COLLECTION_NAME.USERS, userId)

    await updateDoc(user, {
      groups: arrayRemove({ groupId, groupName }),
    })

    const ref = doc(db, COLLECTION_NAME.GROUP, companyId, "list", groupId)
    await updateDoc(ref, {
      members: arrayRemove({ userId, name: fullName }),
    })
    return []
  },
)

export default removeUserFromGroup
