import { createAsyncThunk } from "@reduxjs/toolkit"
import { addDoc, collection, getDocs, query, where } from "@firebase/firestore"
import { doc, Timestamp, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { uploadContentState } from "@/firebase/firebaseHelpers/articles/uploadContentState"
import { handleGetDevicesAndLang } from "@/firebase/firebaseHelpers/pushNotifications/handleGetDevicesAndLang"
import handlePushNotifications from "@/firebase/firebaseHelpers/pushNotifications/handlePushNotifications"
import removeNotRegisteredDevice from "@/firebase/firebaseHelpers/user/removeNotRegisteredDevice"
import { returnTransformedTags } from "@/helpers/handleMakeTags"
import handleSliceRelatedArticle from "@/helpers/slicedRelatedArticle"
import handleTags from "@/helpers/handleTags"
import { ARTICLE_TYPE } from "@/enums/articleType"
import { ARTICLE_STATUS } from "@/enums/articleStatus"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ERROR_CODE, LANGUAGE } from "@/enums"
import { IGroup } from "@/types/IGroup"
import { IRelatedArticle } from "@/types/IRelatedArticles"
import { IFirebaseFile } from "@/types/IFirebaseFile"

interface INewArticleDetails {
  author: string
  status: ARTICLE_STATUS
  type: ARTICLE_TYPE
  title: string
  groups: IGroup[]
  promoted: boolean
  pushNotifications: boolean
  featureImage?: IFirebaseFile
  contentStateMarkdown: string
  contentStateHtml: string
  defaultLanguage: string
  availableLanguages: string[]
  authorImage: IFirebaseFile
  video: IFirebaseFile[]
  imagesGallery: IFirebaseFile[]
}

const addArticle = createAsyncThunk(
  "/articles/addArticle",
  async (
    {
      companyId,
      newArticleDetails,
      handleRedirectToArticle,
      handleErrorMessage,
      token,
    }: {
      companyId: string
      newArticleDetails: INewArticleDetails
      handleRedirectToArticle: (uid: string) => void
      handleErrorMessage: (error: unknown) => void
      token: string
    },
    extra,
  ) => {
    const {
      title,
      author,
      defaultLanguage,
      featureImage,
      contentStateMarkdown,
      contentStateHtml,
      groups,
      promoted,
      pushNotifications,
      status,
      type,
      availableLanguages,
      authorImage,
      video,
      imagesGallery,
    } = newArticleDetails

    const { articles } = extra.getState().articles
    const { users } = extra.getState().users
    const { groups: allGroups } = extra.getState().groups

    const membersIds: string[] = []
    let relatedArticlesId: string[] = []

    let newRelatedArticles: IRelatedArticle[] = []
    let relatedArticles: IRelatedArticle[] = []

    if (groups.length) {
      const groupIds = groups.map(({ groupId }) => groupId)

      const filteredGroups = allGroups.filter(({ id }) => groupIds.includes(id))

      filteredGroups.forEach(({ members }) =>
        members.forEach(({ userId }) => membersIds.push(userId)),
      )

      const articlesWithSameGroup = articles.filter(({ groups: groupLists }) =>
        groupLists.find(({ groupId }) => groupIds.includes(groupId)),
      )

      relatedArticlesId = articlesWithSameGroup.map(({ uid }) => uid)

      if (articlesWithSameGroup) {
        newRelatedArticles = articlesWithSameGroup.map(
          ({
            tagsUK,
            tagsPL,
            tagsDE,
            tagsEN,
            createdAt,
            relatedArticles: relatedArticle,
            promoted: promotedArticle,
            pushNotifications: pushNotificationsArticle,
            translations: translationsArticle,
            relatedArticlesId: idRelatedArticles,
            uid,
            ...rest
          }) => ({
            articleId: uid,
            createdAt: Timestamp.fromDate(new Date(createdAt)),
            translations: {
              [LANGUAGE.PL]: {
                title: translationsArticle.pl?.title ? translationsArticle.pl.title : "",
              },
              [LANGUAGE.EN]: {
                title: translationsArticle.en?.title ? translationsArticle.en.title : "",
              },
              [LANGUAGE.UK]: {
                title: translationsArticle.uk?.title ? translationsArticle.uk.title : "",
              },
              [LANGUAGE.DE]: {
                title: translationsArticle.de?.title ? translationsArticle.de.title : "",
              },
            },
            ...rest,
          }),
        )
        relatedArticles = handleSliceRelatedArticle(newRelatedArticles)
      }
    }
    const parsedTittle = returnTransformedTags([title.toLowerCase()])
    const parsedAuthor = returnTransformedTags([author.toLowerCase()])

    const newTags = [...parsedTittle, ...parsedAuthor]

    const newValueTags = handleTags(availableLanguages, newTags as string[])

    const fileNameHtml = `${companyId}/articles/${title}-${defaultLanguage}.html`
    const urlHtml = await uploadContentState(contentStateHtml, fileNameHtml)

    const fileNameMarkDown = `${companyId}/articles/${title}-${defaultLanguage}.md`
    const urlMarkDown = await uploadContentState(contentStateMarkdown, fileNameMarkDown)

    if (urlMarkDown) {
      const newArticleInfo = {
        author,
        relatedArticles,
        relatedArticlesId,
        authorImage: {
          storageId: authorImage.storageId,
          storageUrl: authorImage.storageUrl,
        },
        createdAt: Timestamp.now(),
        featuredImage: {
          storageId: featureImage?.storageId,
          storageUrl: featureImage?.storageUrl,
        },
        groups,
        promoted,
        pushNotifications,
        ...newValueTags,
        translations: {
          [defaultLanguage]: {
            content: {
              contentStorageId: fileNameMarkDown,
              contentUrl: urlMarkDown,
              contentHtmlStorageId: fileNameHtml,
              contentHtmlUrl: urlHtml,
            },
            imagesGallery,
            video,
            status,
            title,
            notificationsSent: status === ARTICLE_STATUS.PUBLISHED,
            isPushNotificationsSent: pushNotifications,
          },
        },
        type,
      }
      if (promoted) {
        const q = query(
          collection(db, COLLECTION_NAME.ARTICLES, companyId, "list"),
          where("promoted", "==", true),
        )
        const querySnapshot = await getDocs(q)

        let idArticleForUpdate = ""

        querySnapshot.docs.forEach((item) => (idArticleForUpdate = item.id))
        if (idArticleForUpdate) {
          const articleRef = doc(
            db,
            COLLECTION_NAME.ARTICLES,
            companyId,
            "list",
            idArticleForUpdate,
          )

          await updateDoc(articleRef, {
            promoted: false,
          })
        }
      }

      const articleRef = await collection(db, COLLECTION_NAME.ARTICLES, companyId, "list")
      const articleDoc = await addDoc(articleRef, {
        ...newArticleInfo,
      })

      if (status === ARTICLE_STATUS.PUBLISHED) {
        const groupIds = groups.map(({ groupId }) => groupId)
        const notificationsRef = await collection(
          db,
          COLLECTION_NAME.NOTIFICATIONS,
          companyId,
          "list",
        )
        await addDoc(notificationsRef, {
          articleId: articleDoc.id,
          author,
          authorImage: {
            storageId: authorImage.storageId,
            storageUrl: authorImage.storageUrl,
          },
          groupIds,
          groups,
          newUsers: membersIds,
          previousUsers: [],
          createdAt: Timestamp.fromDate(new Date()),
          title,
          language: defaultLanguage,
        })
      }

      if (status === ARTICLE_STATUS.PUBLISHED && pushNotifications) {
        const currentArticle = newArticleInfo as any
        const usersForNotifications = handleGetDevicesAndLang(
          currentArticle,
          users,
          defaultLanguage,
        )

        await Promise.all(
          usersForNotifications.map(async ({ deviceId, lang, userId }) => {
            await handlePushNotifications(
              deviceId,
              articleDoc.id,
              lang,
              userId,
              author,
              handleErrorMessage,
              token,
            )
          }),
        )
      }

      return handleRedirectToArticle(articleDoc.id)
    }
  },
)

export default addArticle
