import { createAsyncThunk } from "@reduxjs/toolkit"
import { addDoc, collection } from "@firebase/firestore"
import { arrayUnion, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

interface IUser {
  userId: string
}

interface IData {
  members: IUser[]
  groups: {
    groupName: string
    groupId: string
  }
  companyId: string
}

const addToGroup = createAsyncThunk(
  "/users/addNewUser",
  async ({ newUserDetails }: { newUserDetails: IData }, extra) => {
    const { users } = extra.getState().users
    const { groups: allGroups } = extra.getState().groups

    const { groups, companyId, members } = newUserDetails

    const { groupName } = groups

    const isNewGroup = allGroups.some((group) => group.groupName === groupName)

    const ids = members.map((item) => item.userId)

    const targetUsers = users.filter(({ userId }) => ids.includes(userId))

    const usersWithAddedGroup = targetUsers.filter(
      (user) => !user.groups?.find((item) => item.groupId === groups.groupId),
    )

    if (!isNewGroup) {
      const dbRef = await collection(db, COLLECTION_NAME.GROUP, companyId, "list")
      const res = await addDoc(dbRef, {
        groupName,
        members: newUserDetails.members,
      })

      await Promise.all(
        usersWithAddedGroup.map(async (item) => {
          const userRef = await doc(db, COLLECTION_NAME.USERS, item.userId)
          await updateDoc(userRef, {
            groups: arrayUnion({
              groupName,
              groupId: res.id,
            }),
          })
        }),
      )
      return
    }

    await Promise.all(
      usersWithAddedGroup.map(async (item) => {
        const userRef = await doc(db, COLLECTION_NAME.USERS, item.userId)
        await updateDoc(userRef, {
          groups: arrayUnion({
            groupName,
            groupId: groups.groupId,
          }),
        })
      }),
    )

    await Promise.all(
      usersWithAddedGroup.map(async (user) => {
        const groupRef = await doc(db, COLLECTION_NAME.GROUP, companyId, "list", groups.groupId)
        await updateDoc(groupRef, {
          members: arrayUnion({
            userId: user.userId,
            name: user.name.fullName,
          }),
        })
      }),
    )
  },
)
export default addToGroup
