import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const removeNotRegisteredDevice = async (deviceId: string, userId: string) => {
  const userRef = await doc(db, COLLECTION_NAME.USERS, userId)

  await updateDoc(userRef, {
    deviceId: arrayRemove(deviceId),
  })
}

export default removeNotRegisteredDevice
