import { createSlice } from "@reduxjs/toolkit"
import { CATEGORIES } from "@/consts/reducer"
import { IError } from "@/redux/store/groups/types"
import { ICategory } from "@/types/ICategory"
import fetchAllCategories from "./actions/fetchAllCategories"
import {
  addCategory,
  addChildCategory,
  editCategory,
  editChildCategory,
  removeCategory,
  removeChildCategory,
  removeCategories,
  removeChildCategories,
} from "./actions"

export const initialCategory: ICategory[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  categories: initialCategory,
  isLoading: false,
  error: initialError,
}

const categorySlice = createSlice({
  name: CATEGORIES,
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.categories = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllCategories.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchAllCategories.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchAllCategories.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(removeCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(addCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(addCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(addChildCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(addChildCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addChildCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(removeChildCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeChildCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeChildCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(editCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(editCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(editCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(editChildCategory.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(editChildCategory.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(editChildCategory.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(removeCategories.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeCategories.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeCategories.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(removeChildCategories.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(removeChildCategories.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(removeChildCategories.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const { setCategory } = categorySlice.actions

export default categorySlice.reducer
