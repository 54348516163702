import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import Select, { CSSObjectWithLabel } from "react-select"
import i18n from "@/i18n"

import { yupResolver } from "@hookform/resolvers/yup/dist/yup"
import { INPUT_TYPE } from "@/enums/inputType"
import { useAppDispatch, useAppSelector, useHandleErrorMessage } from "@/hooks"
import Input from "@/components/Inputs/Input"
import BottomSideForm from "@/components/Forms/BottomSideForm"
import { validationCategorySchema } from "@/validationSchema"
import { addCategory, addChildCategory } from "@/redux/store/categories/actions"
import { NONE_VALUE } from "@/consts/selectNoneValue"
import { LANGUAGE } from "@/enums"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

interface FormCategoryProps {
  toggleModal: () => void
}

const FormCategory = ({ toggleModal }: FormCategoryProps) => {
  const { t } = useTranslation("common")
  const { handleErrorMessage } = useHandleErrorMessage()
  const currentLanguage = i18n.language
  const { admin } = useAppSelector((root) => root.admin)
  const { categories } = useAppSelector((root) => root.categories)
  const { settings } = useAppSelector((root) => root.settings)
  const dispatch = useAppDispatch()
  const { defaultLanguage } = settings.settings
  const { companyId } = admin
  const selectInitValue = { value: NONE_VALUE, label: t("forms.category.none") }
  const initCategoryNames: ICategoryFormValues["categoryNames"] =
    settings.availableLanguages.reduce((prevValue, value) => ({ ...prevValue, [value]: "" }), {})

  const selectStyle = {
    control: (styles: CSSObjectWithLabel) => ({
      ...styles,
      borderColor: "",
      borderRadius: "8px",
    }),
    placeholder: (provided: CSSObjectWithLabel) => ({
      ...provided,
      color: "#808099",
    }),
  }

  const handleNameLanguage = (namePL: string, nameEN: string, nameDE: string, nameUK: string) => {
    switch (currentLanguage || defaultLanguage) {
      case LANGUAGE.PL:
        return namePL
        break
      case LANGUAGE.EN:
        return nameEN
        break
      case LANGUAGE.DE:
        return nameDE
        break
      case LANGUAGE.UK:
        return nameUK
        break
    }
  }

  const filteredCategories = categories.filter(({ documents }) => !documents.length)

  const parentCategories = filteredCategories.map(
    ({ namePL, nameDE, nameEN, nameUK, categoryId }) => ({
      value: categoryId,
      label: handleNameLanguage(namePL, nameEN, nameDE, nameUK),
    }),
  )

  parentCategories.unshift(selectInitValue)

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICategoryFormValues>({
    defaultValues: {
      categoryNames: initCategoryNames,
    },
    resolver: yupResolver(validationCategorySchema(initCategoryNames)),
    mode: "all",
  })

  const onSubmit: SubmitHandler<ICategoryFormValues> = async ({
    categoryNames,
    parentCategory,
  }) => {
    if (parentCategory === NONE_VALUE) {
      try {
        dispatch(
          addCategory({
            companyId,
            categoryNames,
          }),
        )
        toggleModal()
      } catch (error: unknown) {
        handleErrorMessage(error)
      }
    } else {
      try {
        dispatch(
          addChildCategory({
            companyId,
            categoryNames,
            parentCategory,
          }),
        )
        toggleModal()
      } catch (error: unknown) {
        handleErrorMessage(error)
      }
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full flex-grow justify-between"
    >
      <div className="flex flex-col gap-2 pt-8 px-8 ">
        {Object.keys(initCategoryNames).map((lang) => {
          return (
            <div key={lang}>
              <label className="text-greySilver text-xs">
                {t(`forms.name`)} {lang.toLocaleUpperCase()}
              </label>
              <Input
                register={register}
                type={INPUT_TYPE.TEXT}
                id={`categoryNames.${lang}`}
                placeholder={t("forms.category.placeholderName")}
                modalInput={true}
              />
              <div className="text-red text-sm">
                {errors?.categoryNames?.[lang as LANGUAGE]?.message}
              </div>
            </div>
          )
        })}
        <label className="text-greySilver text-xs mt-2">
          {t("forms.category.choose_parent_category")}
        </label>
        <Controller
          control={control}
          name="parentCategory"
          render={({ field: { onChange } }) => (
            <Select
              styles={selectStyle}
              options={parentCategories}
              onChange={(value) => onChange(value?.value)}
              placeholder={t("forms.placeholderDepartment")}
              className="text-greySilver text-sm w-440 "
            />
          )}
        />
        <div className="text-red text-sm">{errors.parentCategory?.message}</div>
      </div>
      <BottomSideForm
        toggleModal={toggleModal}
        submitText={t("forms.category.create_category")}
        cancelText={t("groups.cancel")}
      />
    </form>
  )
}

export default FormCategory
