import { createAsyncThunk } from "@reduxjs/toolkit"
import dayjs from "dayjs"
import { arrayRemove, deleteDoc, doc, Timestamp, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { IMember } from "@/redux/store/groups/types"

const removeGroup = createAsyncThunk(
  "/groups/removeGroup",
  async (
    {
      companyId,
      groupId,
      members,
      groupName,
    }: { companyId: string; groupId: string; groupName: string; members: IMember[] },
    extra,
  ) => {
    const { users } = extra.getState().users
    const { articles } = extra.getState().articles

    const usersFromGroup = users.filter((item) =>
      members.some(({ userId }) => item.userId === userId),
    )

    const articlesWithSameGroup = articles.filter(({ groups }) =>
      groups?.find(({ groupId: id }) => groupId === id),
    )
    const articleWithoutDeleteGroup = articlesWithSameGroup.map((item) => ({
      ...item,
      relatedArticlesId: item.relatedArticlesId.filter((relatedId) => relatedId !== groupId),
      groups: item.groups.filter(({ groupId: id }) => id !== groupId),
      relatedArticles: item.relatedArticles.map((value) => ({
        ...value,
        groups: value.groups.filter(
          ({ groupId: relatedArticleGroupId }) => relatedArticleGroupId !== groupId,
        ),
      })),
    }))

    await deleteDoc(doc(db, COLLECTION_NAME.GROUP, companyId, "list", groupId))

    await Promise.all(
      usersFromGroup.map(async (item) => {
        const userRef = doc(db, COLLECTION_NAME.USERS, item.userId)
        await updateDoc(userRef, {
          groups: arrayRemove({ groupId, groupName }),
        })
      }),
    )
    await Promise.all(
      articleWithoutDeleteGroup.map(async (article) => {
        const { createdAt, ...rest } = article
        const timeStampParsed = Timestamp.fromDate(dayjs(createdAt).toDate())
        const articleRef = doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", article.uid)
        await updateDoc(articleRef, {
          createdAt: timeStampParsed,
          ...rest,
        })
      }),
    )
  },
)

export default removeGroup
