import { updateDoc, doc } from "@firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { db } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { IChildCategory } from "@/types/IChildCategory"

const editChildCategory = createAsyncThunk(
  "/knowledgeCategory/editChildCategory",
  async ({
    companyId,
    parentCategoryId,
    newChildCategories,
  }: {
    companyId: string
    parentCategoryId: string
    newChildCategories: IChildCategory[]
  }) => {
    const dbRef = await doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", parentCategoryId)
    updateDoc(dbRef, { childCategories: newChildCategories })
  },
)

export default editChildCategory
