import { Dispatch } from "redux"
import {
  configureStore,
  combineReducers,
  AsyncThunkPayloadCreator,
  AsyncThunk,
} from "@reduxjs/toolkit"
import groupState from "@/redux/store/groups/reducer"
import users from "@/redux/store/users/reducer"
import settings from "@/redux/store/companySettings/reducer"
import admin from "@/redux/store/admin/reducer"
import articles from "@/redux/store/articles/reducer"
import categories from "@/redux/store/categories/reducer"
import images from "@/redux/store/images/reducer"
import videos from "@/redux/store/videos/reducer"

declare module "@reduxjs/toolkit" {
  type AsyncThunkConfig = {
    state?: RootState
    dispatch?: Dispatch
    extra?: unknown
    rejectValue?: unknown
    serializedErrorType?: unknown
  }

  function createAsyncThunk<
    Returned,
    ThunkArg = void,
    ThunkApiConfig extends AsyncThunkConfig = {
      state: RootState // this line makes a difference
    },
  >(
    typePrefix: string,
    payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>,
    options?: any,
  ): AsyncThunk<Returned, ThunkArg, ThunkApiConfig>
}

const store = configureStore({
  reducer: combineReducers({
    groups: groupState,
    users,
    settings,
    admin,
    categories,
    articles,
    images,
    videos,
  }),
})

export type RootState = ReturnType<typeof store.getState>

export type AppDispatch = typeof store.dispatch

export default store
