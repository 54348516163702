import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteDoc, doc } from "firebase/firestore"
import { deleteObject, ref } from "@firebase/storage"

import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ICheckboxValue } from "@/types/ICheckboxValue"

const removeCategories = createAsyncThunk(
  "/knowledgeCategory/removeCategories",
  async ({
    companyId,
    categoriesToDelete,
    urlsToDelete,
    urlsContentToDelete,
  }: {
    companyId: string
    categoriesToDelete: ICheckboxValue
    urlsToDelete: string[]
    urlsContentToDelete: string[]
  }) => {
    categoriesToDelete.childCategory.map(async (categoryToDelete) => {
      await deleteDoc(doc(db, COLLECTION_NAME.CATEGORIES, companyId, "list", categoryToDelete))
    })
    await Promise.all(urlsContentToDelete.map(async (url) => await deleteObject(ref(storage, url))))
    await Promise.all(urlsToDelete.map(async (url) => await deleteObject(ref(storage, url))))
  },
)

export default removeCategories
