export enum ERROR_CODE {
  INVALID_EMAIL = "auth/invalid-email",
  WRONG_PASSWORD = "auth/wrong-password",
  USER_NOT_FOUND = "auth/user-not-found",
  INVALID_ACTION_CODE = "auth/invalid-action-code",
  NUMBER_TOO_SHORT = "TOO_SHORT",
  NUMBER_TOO_LONG = "TOO_LONG",
  USER_WITH_THIS_NUMBER_EXISTS = "The user with the provided phone number already exists.",
  TOKEN_NOT_REGISTERED = "messaging/registration-token-not-registered",
  INVALID_FORMAT_NUMBER = "The phone number must be a non-empty E.164 standard compliant identifier string.",
}
