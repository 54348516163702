import { createSlice } from "@reduxjs/toolkit"
import fetchFilteredVideos from "@/redux/store/videos/actions/fetchFilteredVideos"
import fetchVideos from "@/redux/store/videos/actions/fetchVideos"
import { IError } from "@/redux/store/groups/types"
import { VideoReducer } from "@/redux/store/videos/types"
import { VIDEOS } from "@/consts/reducer"

const initialVideos: VideoReducer[] = []
const initialFilteredVideos: VideoReducer[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  videos: initialVideos,
  filteredVideos: initialFilteredVideos,
  lastElementId: undefined,
  filterLastElementId: undefined,
  alreadyLoadedIndex: [0],
  alreadyLoadedFilterIndex: [0],
  isLoading: false,
  error: initialError,
}

const videosSlice = createSlice({
  name: VIDEOS,
  initialState,
  reducers: {
    setVideos: (state, action) => {
      state.videos = action.payload
    },
    setFilteredVideos: (state, action) => {
      state.filteredVideos = action.payload
    },
    addVideo: (state, action) => {
      state.videos = [...state.videos, ...action.payload]
    },
    addFilteredVideo: (state, action) => {
      state.filteredVideos = [...state.filteredVideos, ...action.payload]
    },
    setLastElementId: (state, action) => {
      state.lastElementId = action.payload
    },
    setFilteredLastElementId: (state, action) => {
      state.filterLastElementId = action.payload
    },
    setNewIndexLoaded: (state, action) => {
      state.alreadyLoadedIndex = [...state.alreadyLoadedIndex, action.payload]
    },
    setNewFilteredIndexLoaded: (state, action) => {
      state.alreadyLoadedFilterIndex = [...state.alreadyLoadedFilterIndex, action.payload]
    },
    setResetFilteredVideos: (state) => {
      state.filteredVideos = []
      state.alreadyLoadedFilterIndex = [0]
      state.filterLastElementId = undefined
    },
    setResetVideo: (state) => {
      state.videos = []
      state.lastElementId = undefined
      state.alreadyLoadedIndex = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVideos.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchVideos.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchVideos.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(fetchFilteredVideos.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchFilteredVideos.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchFilteredVideos.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const {
  setVideos,
  setLastElementId,
  addVideo,
  setFilteredLastElementId,
  setFilteredVideos,
  addFilteredVideo,
  setNewIndexLoaded,
  setNewFilteredIndexLoaded,
  setResetFilteredVideos,
  setResetVideo,
} = videosSlice.actions

export default videosSlice.reducer
