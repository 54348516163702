const handleInitial = (text: string) => {
  const splitText = text.split(" ")
  if (splitText.length === 1) {
    return text[0]
  }
  const initials = splitText[0].substring(0, 1) + splitText[1].substring(0, 1)
  return initials
}

export default handleInitial
