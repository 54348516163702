import { createSlice } from "@reduxjs/toolkit"
import { USERS } from "@/consts/reducer"
import { IError } from "@/redux/store/groups/types"
import { ITableHeaderEmployees } from "@/redux/store/users/types"
import { fetchAllUsers } from "@/redux/store/users/actions"
import fetchUsersFromGroupCollection from "./actions/fetchUsersFromGroupCollection"
import addNewUser from "@/redux/store/users/actions/addNewUser"
import deleteUser from "@/redux/store/users/actions/deleteUser"
import editUser from "@/redux/store/users/actions/editUser"
import deleteManyUsers from "@/redux/store/users/actions/deleteManyUsers"

export const initialUser: ITableHeaderEmployees[] = []
const initialFilteredUsers: ITableHeaderEmployees[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  users: initialUser,
  filteredUsers: initialFilteredUsers,
  lastElementId: undefined,
  filterLastElementId: undefined,
  alreadyLoadedIndex: [0],
  isLoading: false,
  error: initialError,
}

export const userSlice = createSlice({
  name: USERS,
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload
    },
    clearError: (state) => {
      state.error = initialError
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllUsers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchAllUsers.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchAllUsers.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    //
    builder.addCase(fetchUsersFromGroupCollection.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(fetchUsersFromGroupCollection.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchUsersFromGroupCollection.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(addNewUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(addNewUser.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(addNewUser.rejected, (state, action) => {
      state.error.error = action.error
      state.isLoading = false
    })
    builder.addCase(deleteUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteUser.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(deleteUser.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(deleteManyUsers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(deleteManyUsers.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(deleteManyUsers.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(editUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(editUser.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(editUser.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const { setUsers, clearError } = userSlice.actions

export default userSlice.reducer
