import { addDoc, collection } from "@firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { getFunctions } from "firebase/functions"
import { httpsCallable } from "@firebase/functions"
import { db } from "@/firebase/firebaseConfig"
import { ITableHeaderEmployees } from "@/redux/store/users/types"
import { COLLECTION_NAME } from "@/enums/collectionName"

const addNewGroup = createAsyncThunk(
  "/groups/addNewGroup",
  async ({
    companyId,
    members,
    groupName,
  }: {
    companyId: string
    members: ITableHeaderEmployees[]
    groupName: string
  }) => {
    const users = members.map((item) => ({
      name: item.name.fullName,
      userId: item.userId,
    }))

    const dbRef = await collection(db, COLLECTION_NAME.GROUP, companyId, "list")
    const res = await addDoc(dbRef, {
      groupName,
      members: users,
    })

    const functions = await getFunctions()
    const updateUser = await httpsCallable(functions, "updateUser")
    await Promise.all(
      members.map(
        async ({ department, name, phoneNumber, userId, groups }) =>
          await updateUser({
            department,
            name: name.fullName,
            position: name.position,
            phoneNumber,
            groups: [
              ...groups!,
              {
                groupId: res.id,
                groupName: groupName,
              },
            ],
            uid: userId,
          }),
      ),
    )
  },
)

export default addNewGroup
