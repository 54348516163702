export const baseTheme = {
  "--primary": "#121cde",
  "--background": "#FAFAFA",
  "--white": "#FFFFFF",
  "--gunmental": "#2B303A",
  "--purple": "#0009B7",
  "--gallery": "#EFEFEF",
  "--whiteSmoke": "#F4F4F4",
  "--inputBorder": "#E4E7EB",
  "--lightGrey": "#F2F2F2",
  "--textSelection": "#CEE3FD",
}
