import React from "react"
import { ReactComponent as CancelModal } from "@/assets/images/groups/cancelModal.svg"
import { clearError } from "@/redux/store/users/reducer"
import { useAppDispatch } from "@/hooks"

interface IProps {
  title: string
  toggleModal: () => void
  clearTargetUsers?: () => void
}

export const Header: React.FC<IProps> = ({ title, toggleModal, clearTargetUsers }) => {
  const dispatch = useAppDispatch()

  const handleClearAndToggle = () => {
    dispatch(clearError())
    if (clearTargetUsers) {
      clearTargetUsers()
    }
    toggleModal()
  }

  return (
    <div className="flex flex-row justify-between w-full border-b-2 border-lightGrey">
      <span className="text-gunmetal text-medium px-30 pt-30 pb-15 font-medium">{title}</span>
      <button onClick={handleClearAndToggle} className="px-30">
        <CancelModal />
      </button>
    </div>
  )
}

export default Header
