import { createAsyncThunk } from "@reduxjs/toolkit"
import { arrayRemove, doc, updateDoc } from "firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import { IUser } from "@/types/IUser"
import { COLLECTION_NAME } from "@/enums/collectionName"

const removeManyUsersFromGroup = createAsyncThunk(
  "/groups/removeManyUsersFromGroup",
  async ({
    companyId,
    groupId,
    groupName,
    usersId,
  }: {
    companyId: string
    groupId: string
    groupName: string
    usersId: IUser[]
  }) => {
    await Promise.all(
      usersId.map(async (user) => {
        const userRef = doc(db, COLLECTION_NAME.USERS, user.userId)
        await updateDoc(userRef, {
          groups: arrayRemove({
            groupId: groupId,
            groupName: groupName,
          }),
        })
      }),
    )

    const ref = doc(db, COLLECTION_NAME.GROUP, companyId, "list", groupId)
    await updateDoc(ref, {
      members: arrayRemove(...usersId),
    })
    return []
  },
)

export default removeManyUsersFromGroup
