import * as Yup from "yup"
import i18n from "@/i18n"
import { ICategoryFormValues } from "@/types/ICategoryFormValues"

const spaces = /^(?!\s+$).*/

const nameShema = Yup.string()
  .min(3, i18n.t("forms.category.error.min_length"))
  .matches(spaces, i18n.t("forms.category.error.invalid"))
  .required(i18n.t("forms.category.error.name"))

const validationCategorySchema = (langs: string[]) =>
  Yup.object().shape(
    langs.reduce(
      (prevValue, currentLang) => ({
        ...prevValue,
        [currentLang]: nameShema,
      }),
      {},
    ),
  )

export default validationCategorySchema
