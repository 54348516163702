import React from "react"
import { Navigate } from "react-router-dom"
import { useLocation } from "react-router"
import { useAuthListener } from "@/hooks"
import { NAVIGATION } from "@/enums"

interface Props {
  children: React.ReactNode
}

export const AuthGuard = ({ children }: Props) => {
  const authStatus = useAuthListener()
  const { pathname } = useLocation()

  const { userLogin } = authStatus

  if (userLogin === undefined) {
    // Tu dać loading screen - teraz jest tymczsowo
    return <p>Loading</p>
  }

  if (userLogin === null) {
    return <Navigate to={NAVIGATION.LOGIN} state={{ interceptedPath: pathname }} />
  }

  return <>{children}</>
}
