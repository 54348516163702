export enum MODAL_TYPE {
  ADD_GROUP = "Create new group",
  ADD_PERSON_TO_GROUP = "Add to group",
  ADD_USER = "Add employee",
  EDIT_USER = "Edit user",
  ADD_USER_TO_GROUP = "Add user to group",
  ADD_CATEGORY = "Add category",
  EDIT_CATEGORY = "Edit category",
  EDIT_CHILD_CATEGORY = "Edit child category",
}
