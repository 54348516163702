import { createAsyncThunk } from "@reduxjs/toolkit"
import { collection, getDocs, limit, query, startAfter, where, orderBy } from "@firebase/firestore"
import { db } from "@/firebase/firebaseConfig"
import {
  addFilteredImages,
  setFilteredImages,
  setFilteredLastElementId,
} from "@/redux/store/images/reducer"
import { ImagesReducer } from "@/redux/store/images/types"
import { COLLECTION_NAME } from "@/enums/collectionName"
import { ITEMS_PER_PAGE } from "@/consts/itemsPerPage"

const fetchFilteredImages = createAsyncThunk(
  "/images/fetchFilteredImages",
  async ({ id, filterValue }: { id: string; filterValue?: string }, extra) => {
    let data: ImagesReducer[] = []
    const { filterLastElementId } = extra.getState().images
    const imagesRef = collection(db, COLLECTION_NAME.IMAGES, id, "list")
    if (filterValue && !filterLastElementId) {
      const queryWithFilter = query(
        imagesRef,
        where("tags", "array-contains-any", [filterValue]),
        orderBy("createdAt", "desc"),
        limit(ITEMS_PER_PAGE),
      )
      const querySnapshot = await getDocs(queryWithFilter)
      extra.dispatch(setFilteredLastElementId(querySnapshot.docs[querySnapshot.docs.length - 1]))
      data = querySnapshot.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(setFilteredImages(data))
      return
    }

    if (filterValue && filterLastElementId) {
      const queryWithFilter = query(
        imagesRef,
        where("tags", "array-contains-any", [filterValue]),
        orderBy("createdAt", "desc"),
        limit(ITEMS_PER_PAGE),
        startAfter(filterLastElementId),
      )
      const querySnapshot = await getDocs(queryWithFilter)
      extra.dispatch(setFilteredLastElementId(querySnapshot.docs[querySnapshot.docs.length - 1]))
      data = querySnapshot.docs.map((item) => {
        const { storageId, storageUrl, tags } = item.data()
        return {
          id: item.id,
          storageId,
          storageUrl,
          tags,
        }
      })
      extra.dispatch(addFilteredImages(data))

      return
    }
  },
)

export default fetchFilteredImages
