import { Outlet } from "react-router-dom"
import { useEffect } from "react"
import { setApplyDarkMode, setApplyBaseTheme } from "@/theme/index"

export const AuthLayout = () => {
  const isDarkMode = localStorage.getItem("darkMode")

  useEffect(() => {
    if (isDarkMode === "true") {
      setApplyDarkMode()
      return
    }
    setApplyBaseTheme()
  }, [isDarkMode])

  return <Outlet />
}
