import { doc } from "firebase/firestore"
import { onSnapshot, Unsubscribe } from "@firebase/firestore"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { db } from "@/firebase/firebaseConfig"
import { setSettings } from "@/redux/store/companySettings/reducer"
import { COLLECTION_NAME } from "@/enums/collectionName"

let unsubscribeFetchSettingsFromCompany: Unsubscribe

const fetchSettingsFromCompany = createAsyncThunk(
  "/settings/fetchSettingsFromCompany",
  async (id: string, extra) => {
    unsubscribeFetchSettingsFromCompany = onSnapshot(
      doc(db, COLLECTION_NAME.COMPANIES, id),
      (item) => {
        extra.dispatch(setSettings(item.data()))
      },
    )
  },
)

export { unsubscribeFetchSettingsFromCompany }

export default fetchSettingsFromCompany
