import { createSlice } from "@reduxjs/toolkit"
import { SETTINGS } from "@/consts/reducer"
import { IError } from "@/redux/store/groups/types"
import { ICompanySettingsReducer } from "@/redux/store/companySettings/types"
import fetchSettingsFromCompany from "@/redux/store/companySettings/actions"
import editSettings from "@/redux/store/companySettings/actions/editSettings"

export const initialSettings: ICompanySettingsReducer = {
  availableLanguages: [],
  departments: [],
  name: "",
  id: "",
  settings: {
    colors: {
      darkMode: false,
      mainColor: "",
    },
    defaultLanguage: "",
    displayedName: "",
    logo: "",
    termsAndConditions: "",
  },
}

const initialError: IError = {
  error: null,
}

const initialState = {
  settings: initialSettings,
  isLoading: false,
  error: initialError,
}

const settingsSlice = createSlice({
  name: SETTINGS,
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.settings = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSettingsFromCompany.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    }),
      builder.addCase(fetchSettingsFromCompany.fulfilled, (state) => {
        state.isLoading = false
        state.error.error = null
      }),
      builder.addCase(fetchSettingsFromCompany.rejected, (state, action) => {
        state.isLoading = false
        state.error.error = action.error
      }),
      builder.addCase(editSettings.pending, (state) => {
        state.isLoading = true
        state.error.error = null
      }),
      builder.addCase(editSettings.fulfilled, (state) => {
        state.isLoading = false
        state.error.error = null
      }),
      builder.addCase(editSettings.rejected, (state, action) => {
        state.isLoading = false
        state.error.error = action.error
      })
  },
})

export const { setSettings } = settingsSlice.actions

export default settingsSlice.reducer
