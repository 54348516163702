import { createSlice } from "@reduxjs/toolkit"
import { IError } from "@/redux/store/groups/types"
import { ImagesReducer } from "@/redux/store/images/types"
import fetchImages from "@/redux/store/images/actions/fetchImages"
import { IMAGES } from "@/consts/reducer"
import fetchFilteredImages from "@/redux/store/images/actions/fetchFilteredImages"

const initialImages: ImagesReducer[] = []
const initialFilteredImages: ImagesReducer[] = []

const initialError: IError = {
  error: null,
}

const initialState = {
  images: initialImages,
  filteredImages: initialFilteredImages,
  lastElementId: undefined,
  filterLastElementId: undefined,
  alreadyLoadedIndex: [0],
  alreadyLoadedFilterIndex: [0],
  isLoading: false,
  error: initialError,
}

const imagesSlice = createSlice({
  name: IMAGES,
  initialState,
  reducers: {
    setImages: (state, action) => {
      state.images = action.payload
    },
    setFilteredImages: (state, action) => {
      state.filteredImages = [...state.filteredImages, ...action.payload]
    },
    addImages: (state, action) => {
      state.images = [...state.images, ...action.payload]
    },
    addFilteredImages: (state, action) => {
      state.filteredImages = [...state.filteredImages, ...action.payload]
    },
    setLastElementId: (state, action) => {
      state.lastElementId = action.payload
    },
    setFilteredLastElementId: (state, action) => {
      state.filterLastElementId = action.payload
    },
    setNewIndexLoaded: (state, action) => {
      state.alreadyLoadedIndex = [...state.alreadyLoadedIndex, action.payload]
    },
    setNewFilteredIndexLoaded: (state, action) => {
      state.alreadyLoadedFilterIndex = [...state.alreadyLoadedFilterIndex, action.payload]
    },
    setResetFilteredImages: (state) => {
      state.filteredImages = []
      state.alreadyLoadedFilterIndex = [0]
      state.filterLastElementId = undefined
    },
    setResetImages: (state) => {
      state.images = []
      state.filteredImages = []
      state.filterLastElementId = undefined
      state.lastElementId = undefined
      state.alreadyLoadedIndex = []
      state.alreadyLoadedFilterIndex = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImages.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchImages.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchImages.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
    builder.addCase(fetchFilteredImages.pending, (state) => {
      state.isLoading = true
      state.error.error = null
    })
    builder.addCase(fetchFilteredImages.fulfilled, (state) => {
      state.isLoading = false
      state.error.error = null
    })
    builder.addCase(fetchFilteredImages.rejected, (state, action) => {
      state.isLoading = false
      state.error.error = action.error
    })
  },
})

export const {
  setImages,
  setLastElementId,
  addImages,
  setFilteredLastElementId,
  setFilteredImages,
  addFilteredImages,
  setNewIndexLoaded,
  setResetFilteredImages,
  setNewFilteredIndexLoaded,
  setResetImages,
} = imagesSlice.actions

export default imagesSlice.reducer
