import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteDoc, doc } from "firebase/firestore"
import { deleteObject, ref } from "@firebase/storage"
import { db, storage } from "@/firebase/firebaseConfig"
import { COLLECTION_NAME } from "@/enums/collectionName"

const deleteManyArticles = createAsyncThunk(
  "/articles/removeManyArticles",
  async ({ companyId, articlesId }: { companyId: string; articlesId: string[] }, extra) => {
    const { articles } = extra.getState().articles

    const storageContentForDelete: string[] = []

    const targetArticles = articles.filter(({ uid }) => articlesId.includes(uid))

    targetArticles.forEach((article) => {
      const { translations } = article
      if (translations.pl && translations.pl.content.contentUrl.length) {
        storageContentForDelete.push(translations.pl.content.contentUrl)
      }
      if (translations.en && translations.en.content.contentUrl.length) {
        storageContentForDelete.push(translations.en.content.contentUrl)
      }
      if (translations.de && translations.de.content.contentUrl.length) {
        storageContentForDelete.push(translations.de.content.contentUrl)
      }
      if (translations.uk && translations.uk.content.contentUrl.length) {
        storageContentForDelete.push(translations.uk.content.contentUrl)
      }
    })

    await Promise.all(
      articlesId.map(
        async (id) => await deleteDoc(doc(db, COLLECTION_NAME.ARTICLES, companyId, "list", id)),
      ),
    )

    await Promise.all(
      storageContentForDelete.map(async (item) => await deleteObject(ref(storage, item))),
    )
  },
)

export default deleteManyArticles
