import * as Yup from "yup"
import i18n from "@/i18n"

const phoneRegExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{5}$/

const letterRegExp = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ,.\s'-]+$/i

const validationEditSchema = Yup.object().shape({
  name: Yup.string()
    .required(i18n.t("sign_in.required"))
    .matches(letterRegExp, i18n.t("forms.invalid_format_userName"))
    .max(25)
    .trim(),
  position: Yup.string()
    .required(i18n.t("sign_in.required"))
    .matches(letterRegExp, i18n.t("forms.invalid_format_letters"))
    .max(25),
  department: Yup.object().shape({
    label: Yup.string(),
    value: Yup.string(),
  }),
  phoneNumber: Yup.string().matches(phoneRegExp, i18n.t("forms.invalid_format_phoneNumber")),
})

export default validationEditSchema
