import { signOut } from "@firebase/auth"
import { auth } from "@/firebase/firebaseConfig"

const logout = async (handleError: (error: unknown) => void) => {
  try {
    await signOut(auth)
  } catch (error: unknown) {
    handleError(error)
  }
}

export default logout
